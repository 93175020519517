import React from 'react';

import HomeContact from '../components/Contact/home';

const Contact = () => {
  return (
    <>
      <HomeContact />
    </>
  );
};

export default Contact;
