import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ContactHomepage from '../components/ContactHomepage';
import FormationsSlider from '../components/FormationsSlider/FormationSlider';
import Hero5 from '../components/HeroSection5';
import HighLightHomepage from '../components/HighlightHomepage/HighLightHomepage';
import ProjectBanner from '../components/Homepage/projectbanner';
import Services2 from '../components/Services2';
import ShopSlider from '../components/ShopSlider/ShopSlider';
import Team from '../components/Team';
import TestimonialsBrands from '../components/TestimonialsClients';
import { Context } from '../context/DataContext';

const Home = () => {
  const { orderStatus, setOrderStatus } = useContext(Context);

  useEffect(() => {}, [orderStatus]);

  const notify = () => {
    toast.success('Encomenda processada com sucesso. Obrigado!', {
      onClose: () => setOrderStatus(''),
    });
  };

  return (
    <>
      {orderStatus === 201 ? notify() : <></>}
      <Hero5 />
      <ProjectBanner />
      <FormationsSlider />
      <ShopSlider />
      <Services2 />
      <HighLightHomepage />
      <TestimonialsBrands />
      <Team />
      <ContactHomepage />
      <ToastContainer />
    </>
  );
};

export default Home;
