import React from 'react';
import { FcCalendar } from 'react-icons/fc';
import { Link as LinkR } from 'react-router-dom';

import './ButtonFormation.css';

const ButtonFormations = () => {
  return (
    <>
      <ul className="nav navbar-nav navbar-right ml-auto">
        <li className="nav-item">
          <LinkR className="btn  formation-link" to="/formacao-agendadas">
            <strong></strong> <FcCalendar size={28} />
          </LinkR>
        </li>
      </ul>
    </>
  );
};

export default ButtonFormations;
