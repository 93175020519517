import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserTie, FaBookReader, FaCertificate } from 'react-icons/fa';

const Keypoints = ({ backgroundGrey }) => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <section className={backgroundGrey ? 'home' : ''} id="">
        <div className="container p-4 text-center">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t('common:key-points.title')}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:key-points.subtitle')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col">
              <FaUserTie size={42} color="#DC3545" />
            </div>
            <div className="col">
              <FaBookReader size={42} color="#DC3545" />
            </div>
            <div className="col">
              <FaCertificate size={42} color="#DC3545" />
            </div>
          </div>
          <div className="row p-3">
            <div className="col">
              <h4>{t('common:key-points.point1')}</h4>
            </div>
            <div className="col">
              <h4>{t('common:key-points.point2')}</h4>
            </div>
            <div className="col">
              <h4>{t('common:key-points.point3')}</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Keypoints;
