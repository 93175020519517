import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProducts = createAsyncThunk(
  'productSlice/getProducts',
  async () => {
    return await axios
      .get('https://www.ifportugal.pt/wp-json/wc/v3/products', {
        auth: {
          username: process.env.REACT_APP_WOO_CONSUMER_USERNAME,
          password: process.env.REACT_APP_WOO_CONSUMER_PASSWORD,
        },
        per_page: 100,
      })
      .then((res) => res.data);
  },
);

const initialState = {
  status: null,
  data: [],
  data_sorted: [],
};

function sortData(data) {
  //Sort by date
  const sortedByData = data
    .slice()
    .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
  //Remove
  const removeElement = sortedByData
    .slice()
    .filter((product) => product.id !== 4575);
  //Get first 3 elements
  const firtThree = removeElement.slice(0, 3);
  return firtThree;
}

const productsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.status = 'Pending';
    },

    [getProducts.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload;
      state.data_sorted = sortData(action.payload);
    },

    [getProducts.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default productsSlice.reducer;
