import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'react-image-webp';

import image from '../../images/certificationbanner/cert.png';
import imagew from '../../images/certificationbanner/cert.webp';
import './CertificationBanner.css';

const CertificationBanner = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <section className="" id="home">
        <div className="row p-5">
          <div className="col-lg-12 text-center">
            <div className="title-box">
              <h2 className="title-alt">
                {t('common:certifications-section.title1')}
              </h2>
              <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                {t('common:certifications-section.title2')}
              </h3>
              <div className="border"></div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Image
            src={image}
            webp={imagew}
            alt="project banner"
            className="img-fluid mx-auto d-block projectbanner rounded"
          />
        </div>
      </section>
    </>
  );
};

export default CertificationBanner;
