import React from "react";
import Moment from "react-moment";

const CardNewComponent = ({ onClick, item }) => {
  return (
    <>
      {" "}
      <div className="col" key={item.id}>
        <div className="card h-100">
          <img
            src={item.image}
            className="card-img-top img-fluid"
            alt="index"
            onClick={() => onClick(item)}
            id={item.id}
          />
          <div className="card-body align-items-center d-flex justify-content-center">
            <h5 className="card-title">{item.title}</h5>
          </div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-danger btn-shadow rounded-pill w-lg animated fadeInDown wow"
              onClick={() => onClick(item)}
              id={item.id}
            >
              Ver mais
            </button>
            <h6 className="text-center pt-4">
              Data:{" "}
              <Moment format="DD/MM/YYYY">
                {new Date(item.createdAt).toDateString()}
              </Moment>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardNewComponent;
