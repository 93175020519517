import { RiCheckboxCircleLine } from "react-icons/ri";
const yes = <RiCheckboxCircleLine id="true" size="20px" color="green" />;

const data_en = () => {
	return [
		{
			id: 1,
			title: "5’S UND VISUELLES MANAGEMENT",
			category1: "Training",
			category2: "Auto",
			category3: "Lean/Produktivität",
			opco: true,
			description:
				"Das 5 ́S und das visuelle Management sind Techniken, die auf kontinuierlicher Verbesserung basieren. Das 5 ́S bezieht alle ein, die in der Organisation arbeiten und mit Regel und Disziplin den Materialfluss fördern, die Arbeitsbedingungen, die Umwelt und die Sicherheit verbessern.",
			objectives:
				"Vermitteln Sie den Auszubildenden die erforderlichen Fähigkeiten für die Operationalisierung von Tools, die es ermöglichen, ein kontinuierliches 5'S-System und ein visuelles Management des Arbeitsplatzes und der Organisation zu gewährleisten, mit dem Ziel, Qualität und Produktivität zu verbessern.",
			online: yes,
			presential: yes,
			online_hours: "4",
			presential_hours: "16",
			associated_training: ["5’S – Facilitator Programm", "5’S – Bewusstsein"],
		},
	];
};

export default data_en;
