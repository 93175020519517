import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Context } from '../../context/DataContext';
import Spinner from '../Spinner/Spinner';
import HomeShop from './HomeShop';
import './Products.css';

const ProductsV2 = () => {
  const { t } = useTranslation(['common']);
  const [filter, setFilter] = useState(1);
  const { products } = useContext(Context);
  const history = useHistory();
  function handleClick(product) {
    history.push({
      pathname: `/produto/${product.id}`,
      state: { product: { ...product } },
    });
  }
  function handleSelectChange(e) {
    setFilter((prev) => Number(prev) - Number(prev) + Number(e.target.value));
  }
  function getBadge(book) {
    if (
      book.id === 12898 ||
      (book.id === 12896) |
        (book.id === 12894) |
        (book.id === 4747) |
        (book.id === 336)
    ) {
      return (
        <div class="ribbon">
          <span>TRADUZIDO </span>
        </div>
      );
    }
  }

  if (products.length > 0) {
    //Remove product with id = 4575 because causes error
    let newArray = null;
    const temp = products.filter((product) => product.id !== 4575);
    newArray = temp;
    //No filter
    if (filter === 1) {
      newArray = temp;
    }
    //Filter by name
    if (filter === 2) {
      newArray.sort((a, b) => {
        let firstProductName = a.name.toLowerCase();
        let secondProductName = b.name.toLowerCase();
        if (firstProductName < secondProductName) {
          return -1;
        }
        if (firstProductName > secondProductName) {
          return 1;
        }
        return 0;
      });
    }
    //Filter by sales
    if (filter === 3) {
      newArray = newArray.sort((a, b) => a.total_sales - b.total_sales);
    }
    //Price
    if (filter === 4) {
      newArray = newArray.sort((a, b) => a.price - b.price);
    }
    if (filter === 5) {
      newArray = newArray.sort((a, b) => b.price - a.price);
    }
    return (
      // parent div to hold the ul and li's
      <>
        {/* <NavbarShop /> */}
        <HomeShop />
        <section class="py-5">
          <div class="container px-4 px-lg-5 mt-2">
            <div class="alert alert-secondary text-center" role="alert">
              <select
                class="custom-select"
                onChange={(e) => handleSelectChange(e)}
              >
                <option selected value="1">
                  Ordenar por:
                </option>
                <option value="2">Nome</option>
                <option value="3">Popularidade</option>
                <option value="4">Preço:menor para maior </option>
                <option value="5">Preço:maior para menor </option>
              </select>
            </div>
            <div class="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 row-cols-xl-4 justify-content-center">
              {newArray.map((product) => (
                <div class="col mb-5">
                  <div class="card h-100" onClick={() => handleClick(product)}>
                    {getBadge(product)}
                    <img
                      class="card-img-top mt-3"
                      src={product.images[0].src}
                      alt={product.images[0].name}
                    />

                    <div class="card-body p-4">
                      <div class="text-center">
                        <h5 class="fw-bolder">{product.name}</h5>
                        <h5>{product.price}€</h5>
                      </div>
                    </div>

                    <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => handleClick(product)}
                        >
                          {t('common:shopPage.buttonDetail')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </>
    );
  } else {
    return (
      <>
        <HomeShop />
        <Spinner />
      </>
    );
  }
};

export default ProductsV2;
