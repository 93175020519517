import React, { useState, useContext, useEffect } from "react";
import NavbarEvents from "../components/Events/NavbarEvents";
import Home2 from "../components/Events/home2";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import { useTranslation } from "react-i18next";
import "./event.css";

const Event = () => {
  const { t } = useTranslation(["common"]);
  let { eventID } = useParams();
  const { events } = useContext(Context);
  const [event, setEvent] = useState();
  const [start, setStart] = useState({});
  const [end, setEnd] = useState({});

  useEffect(() => {
    function getEvent() {
      let event = events.find((ev) => ev.info.urlParameter === eventID);
      if (event !== undefined) {
        setEvent(event);
        let start = new Date(event.start).toLocaleDateString();
        let end = new Date(event.end).toLocaleDateString();
        setStart(start);
        setEnd(end);
      }
    }
    getEvent();
  }, [events,eventID]);

  if (event === undefined) {
    return (
      <>
        <section>
          <div className="container">
            <div class="full-page-loader">
              <img width="200" src="/images/logowebp.webp" alt="OPCO logo" />
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      {event && (
        <>
          <NavbarEvents />
          <Home2 eventName={event.title} />
          <section>
            <div className="container">
              <div className="col py-5">
                <div className="row">
                  <div className="text-center pb-3">
                    <img
                      src={event.info.image}
                      alt=""
                      className="img-fluid rounded image-event pt-5"
                    />
                  </div>
                </div>
                <div className="row text-center pb-3">
                  <h3>
                    <strong>{t("common:events.calendar.label1")}:</strong>{" "}
                    {start}
                  </h3>
                </div>
                <div className="row text-center pb-3">
                  <h3>
                    <strong>{t("common:events.calendar.label2")}:</strong> {end}
                  </h3>
                </div>
                <div className="row text-center pb-3">
                  <h3>
                    <strong>{t("common:events.calendar.label4")}:</strong>{" "}
                    {event.info.schedule}
                  </h3>
                </div>
                <div className="row text-center pb-3">
                  <h3>
                    <strong>
                      {t("common:events.calendar.label5")}:
                      <a href={event.url} target="_blank" rel="noreferrer">
                        {" "}
                        {t("common:events.calendar.label5_text")}
                      </a>
                    </strong>
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default Event;
