import React from "react";

const ButtonsBeforeNext = (props) => {
	const buttonsToShow = props.buttons;
	const labelNext = "Next step";
	const labelPrevious = "Previous step";
	const next = props.step;
	const before = next - 2;

	return (
		<>
			{buttonsToShow === 1 && (
				<>
					<button
						type="button"
						class="btn btn-primary"
						onClick={(e) => {
							e.preventDefault();
							props.setcurrentStep(next);
						}}
					>
						{labelNext}
					</button>
				</>
			)}
			{buttonsToShow === 2 && (
				<>
					<div class="row justify-content-between">
						<div className="col-2">
							<button
								type="button"
								class="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									props.setcurrentStep(before);
								}}
							>
								{labelPrevious}
							</button>
						</div>
						<div className="col-2">
							<button
								type="button"
								class="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									props.setcurrentStep(next);
								}}
							>
								{labelNext}
							</button>
						</div>
					</div>
				</>
			)}
			{buttonsToShow === 3 && (
				<>
					<div class="row justify-content-between">
						<div className="col-2">
							<button
								type="button"
								class="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									props.setcurrentStep(before);
								}}
							>
								{labelPrevious}
							</button>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ButtonsBeforeNext;
