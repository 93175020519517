import React from "react";
import Home from "../components/News/home";
import News from "../components/Events/news";

const Notices = () => {
  return (
    <>
      <Home />
      <News />
    </>
  );
};

export default Notices;
