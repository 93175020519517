/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import 'react-awesome-button/dist/themes/theme-blue.css';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FaGlobe } from 'react-icons/fa';
import Image from 'react-image-webp';
import { Link as LinkR, withRouter, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Context } from '../../context/LanguageContext';
import logo from '../../images/logo.png';
import logow from '../../images/logo.webp';
import ButtonFormations from '../ButtonFormation/ButtonFormations';
import ButtonShopCart from '../ButtonShopCart/ButtonShopCart';
import './Navigation.css';

const Navigation = () => {
  const { language, handleLanguage } = useContext(Context);
  const { t } = useTranslation(['common']);
  let location = useLocation();
  const menuLinks = [
    { path: '/', label: 'Home' },
    { path: '/formacao', label: t('common:navigation.item1') },
    { path: '/quem-somos', label: t('common:navigation.item4') },
    { path: '/noticias', label: t('common:navigation.item5') },
    { path: '/contactos', label: t('common:navigation.item6') },
  ];
  function renderMenuLink({ path, label }) {
    const isActive = location.pathname === path;
    return (
      <li className="nav-item linkS" key={path}>
        <LinkR className={`nav-link ${isActive ? 'current' : ''}`} to={path}>
          {label}
        </LinkR>
      </li>
    );
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom" id="navbar-sticky">
        <div className="container">
          <LinkR className="navbar-brand logo" to="/">
            <Image src={logo} webp={logow} alt="" className="logo" />
          </LinkR>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <MdMenu /> */}
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav navbar-center mx-auto" id="mySidenav">
              {menuLinks.map((menuLink) => renderMenuLink(menuLink))}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li
                className="pr-3"
                data-tip={t('common:navbar.item10')}
                data-type="info"
              >
                <ButtonFormations />
                <ReactTooltip />
              </li>
              <li
                className="pr-3"
                data-tip={t('common:navbar.shopTooltip')}
                data-type="info"
              >
                <ButtonShopCart />
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  hfer="#"
                >
                  {' '}
                  <IconContext.Provider
                    value={{ color: '#cc2224', size: '2em' }}
                  >
                    <FaGlobe />
                  </IconContext.Provider>
                </a>

                <ul
                  className="dropdown-menu arrow"
                  aria-labelledby="navbarDropdown"
                >
                  <li className="langugeLi">
                    <a onClick={() => handleLanguage('pt')} hfer="#">
                      {'pt' === language ? <b>Português</b> : 'Português'}
                    </a>
                  </li>
                  <li className="langugeLi">
                    <a onClick={() => handleLanguage('en')} hfer="#">
                      {'en' === language ? <b>English</b> : 'English'}
                    </a>
                  </li>
                  <li className="langugeLi">
                    <a onClick={() => handleLanguage('de')} hfer="#">
                      {'de' === language ? <b>Deutsch</b> : 'Deutsch'}
                    </a>
                  </li>
                  <li className="langugeLi">
                    <a onClick={() => handleLanguage('fr')} hfer="#">
                      {'fr' === language ? <b>Français</b> : 'Français'}
                    </a>
                  </li>
                  <li className="langugeLi">
                    <a onClick={() => handleLanguage('es')} hfer="#">
                      {'es' === language ? <b>Español</b> : 'Español'}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withRouter(Navigation);
