import React, { useContext } from 'react';
import Moment from 'react-moment';
import { useHistory, useParams } from 'react-router-dom';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import Home from '../components/SingleNotice/home';
import Spinner from '../components/Spinner/Spinner';
import { Context } from '../context/DataContext';
import './notice.css';

const Notice = () => {
  let history = useHistory();
  let { id } = useParams();
  const { news } = useContext(Context);
  let notice = history.location.state;

  if (notice !== undefined) {
    notice = history.location.state.notice;
  } else {
    notice = news.filter((notice) => notice.id === id)[0];
  }

  if (notice !== undefined) {
    return (
      <>
        <Home title={notice.title} />
        <BodyNotice
          singleNew={notice}
          documentToReactComponents={documentToReactComponents}
        />
      </>
    );
  } else {
    return (
      <>
        <Home title="Loading.." />
        <Spinner />
      </>
    );
  }
};

export default Notice;

const BodyNotice = ({ singleNew, documentToReactComponents }) => {
  const Bold = ({ children }) => <strong>{children}</strong>;

  const Text = ({ children }) => <p className="">{children}</p>;

  const TextCenter = ({ children }) => (
    <p className="text-center">{children}</p>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => (
        <TextCenter>{children}</TextCenter>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file } = node.data.target.fields;
        const url = file.url;
        return (
          <div className="text-center py-3 ">
            <img src={url} className="img-fluid" alt="{url}" />
          </div>
        );
      },
    },
  };
  return (
    <>
      <div className="container container-notice">
        {/* <h3 className="text-center">
          <strong>{singleNew.title}</strong>
        </h3> */}
        <div className="row p-4">
          <div className="col text-center">
            <img
              src={singleNew.image}
              alt=""
              className="img-thumbnail rounded"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {' '}
            {documentToReactComponents(singleNew.body, options)}
          </div>
        </div>
        <div className="row row justify-content-end p-3">
          <div className="col-1">
            <Moment format="DD/MM/YYYY">
              {new Date(singleNew.createdAt).toDateString()}
            </Moment>
          </div>
        </div>
        <div className="text-center">
          📞 210152492 <p></p>
          📧{' '}
          <a href="mailto:opco@opco.pt" className="text-muted">
            opco@opco.pt
          </a>{' '}
          |{' '}
          <a href="mailto:academia@opco.pt" className="text-muted">
            academia@opco.pt
          </a>
        </div>
        <div className="text-center">
          <ul className="list-inline social-circle">
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/rhopcoacademia/"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <i className="zmdi zmdi-facebook"></i>{' '}
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.youtube.com/channel/UCu7x0s7GqPjUkMF1V11_lPw?view_as=subscriber"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <i className="zmdi zmdi-youtube"></i>{' '}
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://pt.linkedin.com/company/rhopcoacademia"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <i className="zmdi zmdi-linkedin"></i>{' '}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
