import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const TestimonialSection = () => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      {" "}
      <section className="widget row-divider">
        <h4 className="title text-center fw-bold">
          {t("common:courses.aside.title4")}
        </h4>
        <div
          id="testimonials-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test1_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test1_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test2_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test2_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test3_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test3_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test4_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test4_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test5_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test5_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test6_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test6_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test7_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test7_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test8_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test8_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test9_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test9_author")}
                  </span>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <blockquote className="quote">
                <FaQuoteLeft className="quote-icon" />{" "}
                {t("common:about-page.testimonials.test10_text")}
              </blockquote>
              <div className="source">
                <p className="people">
                  <span className="name">
                    {" "}
                    {t("common:about-page.testimonials.test10_author")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialSection;
