import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Context } from '../../context/LanguageContext';
import temp_webp from '../../images/temp.webp';

const CardCourseComponent = ({ item }) => {
  const { language } = useContext(Context);
  let history = useHistory();
  let imgSrc = item.fields.hasOwnProperty('image')
    ? item.fields.image.fields.file.url
    : temp_webp;
  const handleCourseClick = (e, item) => {
    e.preventDefault();
    var str = item.fields.title;
    var temp = str.split(' ').join('-').replace('---', '-').replace('/', '-');
    let url = `formacao/${temp}`;
    history.push(url);
  };
  return (
    <>
      {' '}
      <div className="col-md-6" onClick={(e) => handleCourseClick(e, item)}>
        <div class="card bg-dark text-white image-card bborder-secondary mb-3">
          <img src={imgSrc} class="card-img" alt="..." />
          <div class="card-img-overlay">
            <p class="card-text text-card-red align-text-bottom">
              <b>
                {language === 'pt' ? item.fields.title : item.fields.titleEn}
              </b>
              {/* <b>{item.fields.title}</b> */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardCourseComponent;
