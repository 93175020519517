import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDistricts = createAsyncThunk(
  'districtsSlice/getDistricts',
  async () => {
    const response = await axios.get('https://json.geoapi.pt/distritos');
    return response;
  },
);

const initialState = {
  status: null,
  data: [],
};

const districtsSlice = createSlice({
  name: 'districtsSlice',
  initialState,
  extraReducers: {
    [getDistricts.pending]: (state) => {
      state.status = 'Pending';
    },

    [getDistricts.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload.data;
    },

    [getDistricts.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default districtsSlice.reducer;
