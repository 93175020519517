import React from "react";
import Home from "../components/Events/home";
import CalendarCourses from "../components/Events/calendar";

const Event = () => {
  return (
    <>
      <Home />
      <CalendarCourses />
    </>
  );
};

export default Event;
