import React from 'react';
import { useTranslation } from 'react-i18next';

const TConditions_pt = () => {
  const { t } = useTranslation(['common']);
  return (
    <div className="container">
      <div className="row p-4 justify-content-md-center">
        <div className="col-10">
          {/* <h3>Termos e Condições</h3> */}
          <h4>
            <strong>1. {t('common:term-conditions-page.item1-title')}</strong>
          </h4>
          <p>{t('common:term-conditions-page.item1-text')}</p>
          <h4>
            <strong>2. {t('common:term-conditions-page.item2-title')}</strong>
          </h4>
          <p>{t('common:term-conditions-page.item2-text.item2-text-start')}</p>
          <ol>
            <li>
              {t('common:term-conditions-page.item2-text.item2-text-subitem1')}{' '}
            </li>
            <li>
              {t('common:term-conditions-page.item2-text.item2-text-subitem2')}{' '}
            </li>
            <li>
              {t('common:term-conditions-page.item2-text.item2-text-subitem3')}{' '}
            </li>
            <li>
              {t('common:term-conditions-page.item2-text.item2-text-subitem14')}{' '}
            </li>
            <li>
              {t('common:term-conditions-page.item2-text.item2-text-subitem5')}{' '}
            </li>
          </ol>
          <p>{t('common:term-conditions-page.item2-text.item2-text-end')}</p>
          <h4>
            <strong>3. {t('common:term-conditions-page.item3-title')}</strong>
          </h4>
          <ol>
            <li>
              {t('common:term-conditions-page.item3-text.item3-text-subitem1')}
            </li>
            <li>
              {t('common:term-conditions-page.item3-text.item3-text-subitem2')}
            </li>
          </ol>
          <h4>
            <strong>4. {t('common:term-conditions-page.item4-title')}</strong>
          </h4>
          <p>{t('common:term-conditions-page.item4-text')}</p>
          <h4>
            <strong>5. {t('common:term-conditions-page.item5-title')}</strong>
          </h4>
          <p>{t('common:term-conditions-page.item5-text')}</p>
          <h4>
            <strong>6. {t('common:term-conditions-page.item6-title')}</strong>
          </h4>
          <p>{t('common:term-conditions-page.item6-text')}</p>
          <h5>
            <strong>{t('common:term-conditions-page.item7-title')}</strong>
          </h5>
          <p>{t('common:term-conditions-page.item7-text')}</p>
          <h5>
            <strong>{t('common:term-conditions-page.item8-title')}</strong>
          </h5>
          <p>{t('common:term-conditions-page.item8-text')}</p>
        </div>
      </div>
    </div>
  );
};

export default TConditions_pt;
