import React, { createContext } from "react";

import useLanguage from "./hooks/useLanguage";

const Context = createContext();

function LanguageProvider({ children }) {
	const { handleLanguage, language, isLanguage, loading } = useLanguage();

	return (
		<Context.Provider
			value={{
				handleLanguage,
				language,
				isLanguage,
				loading,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { Context, LanguageProvider };
