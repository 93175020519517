import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Client from '../Contentfull';

export const getBanners = createAsyncThunk(
  'bannersSlice/getBanners',
  async () => {
    return await Client.getEntries({
      content_type: 'homeBanner',
    }).then((res) => formatData(res));
  },
);

function formatData(res) {
  let items = res.items;
  let arr = [];
  items.forEach((item) => {
    let temp = {
      title: item.fields.bannerTitle,
      titleEn: item.fields.bannerTitleEn,
      text: item.fields.bannerText,
      textEn: item.fields.bannerTextEn,
      image: item.fields.bannerImage.fields.file.url,
      url: item.fields.bannerUrl,
      titleWhite: item.fields.titleWhite,
      showButton: item.fields.showButton,
      orderSlider: item.fields.orderSlider,
    };
    arr = [...arr, temp];
  });
  return arr;
}

const initialState = {
  status: null,
  data: [],
};

const bannersSlice = createSlice({
  name: 'bannersSlice',
  initialState,
  extraReducers: {
    [getBanners.pending]: (state) => {
      state.status = 'Pending';
    },
    [getBanners.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload;
    },
    [getBanners.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});
export default bannersSlice.reducer;
