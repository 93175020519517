import React from 'react';
import { useTranslation } from 'react-i18next';

const HighLightHomepage = () => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <section className="section home" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t('common:about-page.title4')}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:about-page.title3')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-lg-6 mx-auto">
              <div className="about-txt">
                {/* <h3>{t("common:about-page.title3")}</h3> */}
                <p>{t('common:about-page.aboutp1')}</p>
                <p>{t('common:about-page.aboutp2')}</p>
                <p>{t('common:about-page.aboutp3')}</p>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-accounts-alt text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">182</span> <br />
                    {t('common:about-page.statistic.clients')}
                  </p>
                </div>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-cloud-outline text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">12</span> <br />
                    {t('common:about-page.statistic.projects')}
                  </p>
                </div>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-globe text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">18</span> <br />
                    {t('common:about-page.statistic.countries')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HighLightHomepage;
