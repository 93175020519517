import React, { createContext } from "react";
import useData from "./hooks/useData";
const Context = createContext();

function DataProvider({ children }) {
	const {
		getEvents,
		getCourses,
		setCoursesFile,
		applyCategorie2Filter,
		setSelect1Value,
		setSelect2Value,
		setSelect3Value,
		handleSelectChange,
		handleImageChange,
		setsearchTextBox,
		setEmailData,
		setSubject,
		setemailStatus,
		setemailError,
		sethasResponse,
		handleSelectPartnerChange,
		getCatalogUrl,
		sendForm,
		setformStatus,
		addToCart,
		removeFromCart,
		calculateSubtotal,
		round,
		makeOrder,
		setOrderStatus,
		partner,
		select4Value,
		hasResponse,
		emailError,
		emailStatus,
		emailData,
		news,
		searchTextBox,
		select1Value,
		select2Value,
		select3Value,
		events,
		courses,
		presential,
		online,
		filteredCourses,
		category1,
		category2,
		banners,
		catalogs,
		formStatus,
		products,
		items,
		subtotal,
		orderStatus,
		hero,
		eventsSlider,
	} = useData();

	return (
		<Context.Provider
			value={{
				getEvents,
				getCourses,
				setCoursesFile,
				applyCategorie2Filter,
				setSelect1Value,
				setSelect2Value,
				setSelect3Value,
				handleSelectChange,
				handleImageChange,
				setsearchTextBox,
				setEmailData,
				setSubject,
				setemailStatus,
				setemailError,
				sethasResponse,
				handleSelectPartnerChange,
				getCatalogUrl,
				sendForm,
				setformStatus,
				addToCart,
				removeFromCart,
				calculateSubtotal,
				round,
				makeOrder,
				setOrderStatus,
				partner,
				select4Value,
				hasResponse,
				emailError,
				emailStatus,
				emailData,
				news,
				searchTextBox,
				select1Value,
				select2Value,
				select3Value,
				events,
				courses,
				presential,
				online,
				filteredCourses,
				category1,
				category2,
				banners,
				catalogs,
				formStatus,
				products,
				items,
				subtotal,
				orderStatus,
				hero,
				eventsSlider,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { Context, DataProvider };
