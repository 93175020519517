import React from "react";

const Text = () => {
  const date = new Date();
  const formatedDate = new Intl.DateTimeFormat("pt-PT").format(date);

  return (
    <>
      <div className="row">
        <div className="fs-5">Date of application: {formatedDate}</div>
      </div>
      <div className="row">
        <div className="fs-5">Preliminary remarks:</div>
        <div className="fs-6">
          <ul>
            <li>
              <>Please submit the following documents for each candidate:</>
              <ul>
                <li>
                  <>this filled in application form</>
                </li>
                <li>
                  <>
                    a copy of your certificate of qualification of the training
                    "VDA 6.3 – Qualification as Process Auditor (2016)"
                  </>
                </li>
                <li>
                  <>
                    proof of completion of a three-day auditor qualification
                    course based on DIN EN ISO 19011 or a qualification for VDA
                    Quality Manager and Internal Auditor
                  </>
                </li>
                <li>
                  <>proof of knowledge of the Automotive Core Tools</>
                </li>
                <li>
                  <>
                    proof of a least five years of full-time professional
                    experience at the production company, at least two years in
                    quality management (personal data sheet). Company
                    apprenticeships can be taken into account for candidates
                    with three years or more of professional experience
                  </>
                </li>
                <li>
                  <>
                    a digital passport photo of the applicant for the auditor
                    card
                  </>
                </li>
              </ul>
            </li>
            <li>
              <>
                <strong>
                  Company apprenticeships are counted toward the candidate's
                  professional experience at a rate of 50%, and only for
                  candidates who have completed a dual course of
                  apprenticeship/study in a technical profession at the
                  production company. Company internships, time as a student
                  trainee, etc. during the course of academic Bachelor's or
                  Master's study programs cannot be counted toward a candidate's
                  professional experience.
                </strong>
              </>
            </li>
            <li>
              <>
                All documents shall be submitted in English. Text files must be
                uploaded in PDF format, the digital passport photo in JPEG or
                PNG format.
              </>
            </li>
            <li>
              <>
                As soon as the candidate is admitted to the examination, a
                confirmation will be sent.
              </>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Text;
