import React from 'react';
import { useLocation } from 'react-router-dom';

import Catalog from '../components/Courses/catalog_v2';
import HomeCourses from '../components/Courses/home';

const Formation_v2 = () => {
  let location = useLocation();

  return (
    <>
      <HomeCourses url={location.pathname} />
      <Catalog url={location.pathname} />
    </>
  );
};

export default Formation_v2;
