import React from 'react';
import 'react-awesome-button/dist/themes/theme-blue.css';
import Image from 'react-image-webp';
import { Link as LinkR } from 'react-router-dom';

import logo from '../../images/logo.png';
import logow from '../../images/logo.webp';
import './Navbar2Elements.css';

const Navbar3 = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark"
        id="navbar-sticky"
      >
        <div className="container d-flex justify-content-center">
          <LinkR className="navbar-brand logo " to="/">
            <Image
              src={logo}
              webp={logow}
              alt=""
              className="logo text-center"
            />
          </LinkR>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <MdMenu /> */}
            <i class="zmdi zmdi-menu"></i>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar3;
