import React from 'react';
import { useTranslation } from 'react-i18next';
import { FcViewDetails } from 'react-icons/fc';
import { Link as LinkR } from 'react-router-dom';

import './ButtonTesting.css';

const ButtonTesting = () => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <ul className="nav navbar-nav navbar-right ml-auto ">
        <li className="nav-item">
          <LinkR
            className="btn btn-outline-danger rounded formation-link"
            to="/testes"
          >
            {t('common:navbar.testsButton')} <FcViewDetails size={30} />
          </LinkR>
        </li>
      </ul>
    </>
  );
};

export default ButtonTesting;
