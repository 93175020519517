import React, { useContext, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';

import { Context } from '../../context/DataContext';

const ButtonShopCart = () => {
  const { items } = useContext(Context);
  useEffect(() => {}, [items]);
  function itemsCount() {
    let count = 0;

    items.forEach((item) => {
      count = count + item.quantity;
    });

    return count;
  }

  return (
    <>
      <ul className="nav navbar-nav navbar-right ml-auto">
        <li className="nav-item">
          <LinkR className="btn rounded formation-link" to="/carro">
            <FaShoppingCart size={28} />{' '}
            <span class="badge bg-secondary">{itemsCount()}</span>
          </LinkR>
        </li>
      </ul>
    </>
  );
};

export default ButtonShopCart;
