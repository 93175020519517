import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBin3Fill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { Context } from '../../context/DataContext';
import HomeShop from '../Shop/HomeShop';
import './CartPage.css';

const CartPage = () => {
  const { t } = useTranslation(['common']);
  const { items, removeFromCart, addToCart } = useContext(Context);
  const history = useHistory();
  useEffect(() => {}, [items]);

  function handleShop() {
    history.push({
      pathname: `/loja`,
    });
  }

  function handleCheckout() {
    history.push({
      pathname: `/checkout`,
    });
  }
  function handleAddCart(product) {
    addToCart(product);
  }

  function checkItemsLenght() {
    if (items.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  let cartItems = null;
  if (items.length > 0) {
    cartItems = items.map((item, index) => (
      <tr className="text-center">
        <th scope="row" className="text-center">
          <img
            src={item.image}
            alt={index}
            className="img-fluid"
            style={{ height: '20%', width: '20%' }}
          />
        </th>
        <td>{item.name}</td>
        <td>
          {/* <span role="button">
            <FaMinus
              color="red"
              size={15}
              onClick={() => handleRemoveCart(item.id)}
            />
          </span> */}
          {item.quantity}
          <span role="button">
            <FaPlus color="red" size={15} onClick={() => handleAddCart(item)} />
          </span>
        </td>
        <td>{item.price}€</td>
        <td>
          <span role="button">
            <RiDeleteBin3Fill
              color="red"
              size={25}
              onClick={() => removeFromCart(item.id)}
            />
          </span>
        </td>
      </tr>
    ));
  } else {
    cartItems = (
      <tr className="text-center">
        <td colspan="5">
          <strong>{t('common:shopPage.products-table.no-products')}</strong>
        </td>
      </tr>
    );
  }

  return (
    <>
      {/* <NavbarShop /> */}
      <HomeShop />{' '}
      <div className="container pt-3">
        <div className="row justify-content-center">
          <div className="col-8">
            <div class="table-responsive">
              <table class="table table-products">
                <thead>
                  <tr className="text-center">
                    <th scope="col">
                      {t('common:shopPage.products-table.header1')}
                    </th>
                    <th scope="col">
                      {' '}
                      {t('common:shopPage.products-table.header2')}
                    </th>
                    <th scope="col">
                      {' '}
                      {t('common:shopPage.products-table.header3')}
                    </th>
                    <th scope="col">
                      {' '}
                      {t('common:shopPage.products-table.header4')}
                    </th>
                    <th scope="col">
                      {' '}
                      {t('common:shopPage.products-table.header5')}
                    </th>
                  </tr>
                </thead>
                {items.length > 0 ? cartItems : <tbody>{cartItems}</tbody>}
                {/* {<tbody>{cartItems}</tbody>} */}
              </table>
            </div>
          </div>
        </div>
        <div className="row row justify-content-center pb-4 text-center">
          <div className="col-4">
            <button className="btn btn-primary" onClick={() => handleShop()}>
              {t('common:shopPage.products-table.button-back-shop')}
            </button>
          </div>
          <div className="col-4">
            <button
              className="btn btn-primary"
              onClick={() => handleCheckout()}
              disabled={checkItemsLenght()}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CartPage;
