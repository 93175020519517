import React from 'react';

import AboutSection from '../components/About/about';
import HomeAbout from '../components/About/home';
import Mission from '../components/About/mission';
import Testimonials2 from '../components/About/testimonials2';
import Team from '../components/Team';

const About = () => {
  return (
    <>
      <HomeAbout />
      <AboutSection />
      <Mission />
      <Team />
      <Testimonials2 />
    </>
  );
};

export default About;
