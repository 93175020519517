import React from "react";

const SectionWhite = ({ children }) => {
  return (
    <div>
      <section className="section news-section" id="">
        {children}
      </section>
    </div>
  );
};

export default SectionWhite;
