import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Client from '../Contentfull';

export const getCatalogs = createAsyncThunk(
  'asideSlice/getCatalogs',
  async () => {
    return await Client.getEntries({
      content_type: 'catalogs',
    }).then((response) => formatData(response));
  },
);

function formatData(response) {
  let items = response.items;
  let arr = [];
  items.forEach((item) => {
    let tempObj = {
      filename: '',
      url: '',
    };
    tempObj.filename = item.fields.name;
    tempObj.url = `https:${item.fields.document.fields.file.url}`;
    arr = [...arr, tempObj];
  });
  return arr;
}

const initialState = {
  status: null,
  data: [],
};

const asideSlice = createSlice({
  name: 'asideSlice',
  initialState,
  extraReducers: {
    [getCatalogs.pending]: (state) => {
      state.status = 'Pending';
    },

    [getCatalogs.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload;
    },

    [getCatalogs.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default asideSlice.reducer;
