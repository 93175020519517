import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkR } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { getFormations } from '../../redux/formationsSlideSlice';
import { getProducts } from '../../redux/productsSlice';
import Spinner from '../Spinner/Spinner';
import './ShopSlider.css';

const ShopSlider = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const productsDataSorted = useSelector((state) => state.products.data_sorted);
  const productsDataStatus = useSelector((state) => state.products.status);
  const history = useHistory();

  useEffect(() => {
    dispatch(getFormations());
    dispatch(getProducts());
  }, [dispatch]);

  function handleClick(product) {
    history.push({
      pathname: `/produto/${product.id}`,
      state: { product: { ...product } },
    });
  }
  function getBadge(book) {
    if (
      book.id === 12898 ||
      (book.id === 12896) |
        (book.id === 12894) |
        (book.id === 4747) |
        (book.id === 336)
    ) {
      return (
        <div class="ribbon">
          <span>TRADUZIDO </span>
        </div>
      );
    }
  }

  return (
    <>
      {/* {productsDataStatus !== 'Fulfilled' ? <Spinner /> : ''} */}
      <section className="home">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t('common:shop.title')}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:shop.title1')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 row-cols-xl-4 justify-content-center">
            {productsDataSorted.map((product) => (
              <div class="col mb-5">
                <div class="card h-100" onClick={() => handleClick(product)}>
                  {getBadge(product)}
                  <img
                    class="card-img-top mt-3"
                    src={product.images[0].src}
                    alt={product.images[0].name}
                  />

                  <div class="card-body p-4">
                    <div class="text-center">
                      <h5 class="fw-bolder">{product.name}</h5>
                      <h5>{product.price}€</h5>
                    </div>
                  </div>

                  <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => handleClick(product)}
                      >
                        {t('common:shop.button-detail')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center p-3">
            <div className="col-8 text-center">
              <LinkR className="btn btn-secondary" to="/loja">
                {t('common:shop.button-all')}
              </LinkR>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopSlider;
