import React from "react";
import { useTranslation } from "react-i18next";

const Home = () => {
	const { t } = useTranslation(["common"]);

	return (
		<>
			<section className="home">
				{/* <div className="bg-overlay"></div> */}
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="home-wrapper home-wrapper-sm text-center">
								<h2 className="animated fadeInDown wow" data-wow-delay=".1s">
									{t("common:events.title")}{" "}
									<span className="text-colored">
										{t("common:events.subtitle")}
									</span>
								</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
