import { configureStore } from '@reduxjs/toolkit';

import counterReducer from '../components/counter/counterSlice';
import asideSlice from '../redux/asideSlice';
import bannersSlice from '../redux/bannersSlice';
import careersSlice from '../redux/careersSlice';
import contactSlice from '../redux/contactSlice';
import countiesSlice from '../redux/countiesSlice';
import districtsSlice from '../redux/districtsSlice';
import eventsSlice from '../redux/eventsSlice';
import formationCatalogSlice from '../redux/formationsCatalogSlice';
import formationsCatalogSlicev2 from '../redux/formationsCatalogSlicev2';
import formationsSlice from '../redux/formationsSlideSlice';
import newsSlice from '../redux/newsSlice';
import parishSlice from '../redux/parishSlice';
import productSlice from '../redux/productsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    news: newsSlice,
    products: productSlice,
    banners: bannersSlice,
    formations: formationsSlice,
    catalogs: asideSlice,
    formationCatalog: formationCatalogSlice,
    formationCatalog2: formationsCatalogSlicev2,
    formCareers: careersSlice,
    formContact: contactSlice,
    events: eventsSlice,
    districts: districtsSlice,
    counties: countiesSlice,
    parishs: parishSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
