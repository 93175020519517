import React from "react";
import Image from "react-image-webp";

const ImgWithFallback = ({
	src,
	fallback,
	type = "image/webp",
	alt,
	...delegated
}) => {
	return (
		<picture>
			<source srcSet={src} type={type} alt={alt} />
			<Image src={fallback} {...delegated} alt={alt} />
		</picture>
	);
};

export default ImgWithFallback;
