import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectBanner from '../Homepage/projectbanner';
import Keypoints from './keypoints';

const AboutSection = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <section className="section" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/iB6UYlpAbbk"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="OPCO_video"
              ></iframe>
            </div>
            <div className="col-lg-6">
              <div className="about-txt">
                <h3>{t('common:about-page.title3')}</h3>
                <p>{t('common:about-page.aboutp1')}</p>
                <p>{t('common:about-page.aboutp2')}</p>
                <p>{t('common:about-page.aboutp3')}</p>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-accounts-alt text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">182</span> <br />
                    {t('common:about-page.statistic.clients')}
                  </p>
                </div>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-cloud-outline text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">12</span> <br />
                    {t('common:about-page.statistic.projects')}
                  </p>
                </div>
                <div className="fun_facts_box text-center">
                  <i className="zmdi zmdi-globe text-colored fun_facts_icons"></i>
                  <p className="fun_facts_title">
                    <span className="facts_numbers">18</span> <br />
                    {t('common:about-page.statistic.countries')}
                  </p>
                </div>
                {/* <div className="fun_facts_box text-center">
									<i className="zmdi zmdi-headset-mic text-colored fun_facts_icons"></i>
									<p className="fun_facts_title">
										<span className="facts_numbers">5</span> <br />
										{t("common:about-page.statistic.parterships")}
									</p>
								</div> */}
              </div>
            </div>
          </div>
        </div>
        <Keypoints backgroundGrey={true} />
        <ProjectBanner backgroundGrey={false} />
      </section>
    </>
  );
};

export default AboutSection;
