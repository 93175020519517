import React from 'react';
import { useTranslation } from 'react-i18next';

const HomeCareers = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      {' '}
      <section className="home">
        {/* <div className="bg-overlay"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-wrapper home-wrapper-sm text-center">
                <h2 className="animated fadeInDown wow" data-wow-delay=".1s">
                  {t('common:carrers-form.title1')}
                  <span className="text-colored"> OPCO</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCareers;
