import { RiCheckboxCircleLine } from "react-icons/ri";
const yes = <RiCheckboxCircleLine id="true" size="20px" color="green" />;

const data_en = () => {
	return [
		{
			id: 1,
			title: "5’S ET GESTION VISUELLE",
			category1: "Formation",
			category2: "Auto",
			category3: "Lean/Productivité",
			opco: true,
			description:
				"Le 5 ́S et le Management Visuel sont des techniques basées sur l'amélioration continue. Le 5 ́S implique tous ceux qui travaillent dans l'organisation et qui, avec règle et discipline, favorisent la circulation des matériaux, améliorent les conditions de travail, l'environnement et la sécurité.",
			objectives:
				"Fournir aux stagiaires les compétences nécessaires à l'opérationnalisation des outils qui permettent de garantir un système continu de 5`S et de gestion visuelle du lieu de travail et de l'organisation, avec l'objectif d'améliorer la qualité et la productivité.",
			online: yes,
			presential: yes,
			online_hours: "4",
			presential_hours: "16",
			associated_training: ["5’S – Programme Facilitateur", "5’S – Conscience"],
		},
	];
};

export default data_en;
