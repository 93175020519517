/* eslint-disable no-useless-computed-key */
import React, { useState, useContext, useEffect } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Swal from 'sweetalert2';

import { Context } from '../../context/DataContext';
import HomeShop from '../Shop/HomeShop';

const Checkoutv1 = () => {
  const { t } = useTranslation(['common']);
  const toastId = React.useRef(null);
  const history = useHistory();

  const {
    items,
    calculateSubtotal,
    subtotal,
    makeOrder,
    orderStatus,
    setOrderStatus,
  } = useContext(Context);

  useEffect(() => {
    calculateSubtotal();
    if (orderStatus === 201) {
      toast.dismiss(toastId.current);
      setOrderStatus('');
      Swal.fire({
        title: 'Encomenda processada com sucesso. Obrigado!',
        showDenyButton: true,
        confirmButtonText: 'Back to homepage.',
        denyButtonText: `Back to online shop.`,
        icon: 'success',
      }).then((result) => {
        if (result.isConfirmed) {
          redirectHomepage();
        } else if (result.isDenied) {
          redirectShop();
        }
      });
    }
    if (orderStatus === 400) {
      toast.update(toastId.current, {
        render: `Ocorreu um erro. Por favor volte a tentar.`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setOrderStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, subtotal, orderStatus, calculateSubtotal]);

  const findFormErrors = () => {
    const { email, vat } = form;
    const newErrors = {};
    if (!email || email === '') newErrors.email = 'Email is missing.';
    if (!vat || vat === '') newErrors.vat = 'VAT is missing.';
    if (checkBox1 === false && checkBox2 === false) {
      newErrors.payment = 'Payment method is missing.';
    }
    if (checkBox3 === false) {
      newErrors.acceptTerms = 'You must accept terms&conditions.';
    }
    if (!items.length > 0) {
      newErrors.emptyCart = 'Empty shop car. Please add items.';
    }
    return newErrors;
  };

  const [orderData, setOrderData] = useState({
    payment_method: '',
    payment_method_title: '',
    set_paid: false,
    first_name: '',
    last_name: '',
    company: '',
    address_1: '',
    city: '',
    postcode: '',
    country: '',
    email: '',
    phone: '',
    vat: '',
    customer_note: '',
    line_items: [],
    shipping_lines: [
      {
        method_id: 'flat_rate',
        method_title: 'Flat Rate',
        total: '10.00',
      },
    ],
  });
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [form, setForm] = useState({});
  const [, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const getTaxes = () => {
    let temp = (subtotal + 10) * 0.23;
    return Number(Math.round(temp * 100) / 100);
  };

  const redirectHomepage = () => {
    history.push({
      pathname: `/`,
    });
  };

  const redirectShop = () => {
    history.push({
      pathname: `/loja`,
    });
  };

  const handleChange = (event) => {
    if (!(typeof event === 'object' && event !== null)) {
      setOrderData((prevState) => ({
        ...prevState,
        country: event,
      }));
    } else {
      const { name, value } = event.target;
      setField(name, value);
      setOrderData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      for (const key in newErrors) {
        toastId.current = toast.error(`${newErrors[key]}`);
      }
    } else {
      toastId.current = toast.loading('Processing order...');
      makeOrder(orderData);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target.id === 'checkbox1') {
      setCheckBox2((prevCheck) => !prevCheck);
      setOrderData((prevState) => ({
        ...prevState,
        ['payment_method']: 'bacs',
        ['payment_method_title']: 'Direct Bank Transfer',
      }));
    }
    if (event.target.id === 'checkbox2') {
      setCheckBox1((prevCheck) => !prevCheck);
      setOrderData((prevState) => ({
        ...prevState,
        ['payment_method']: 'multibanco_ifthen_for_woocommerce',
        ['payment_method_title']: 'Pagamento de Serviços no Multibanco',
      }));
    }
    if (event.target.id === 'checkbox3') {
      setCheckBox3((prevCheck) => !prevCheck);
    }
  };
  let cartItems = null;
  if (items.length > 0) {
    cartItems = (
      <tbody>
        {items.map((item, index) => (
          <tr className="text-center">
            <th scope="row" className="text-center">
              <img
                src={item.image}
                alt={index}
                className="img-fluid"
                style={{ height: '20%', width: '20%' }}
              />
            </th>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>{item.price}€</td>
          </tr>
        ))}
        <tr>
          <td className="text-right" colspan="3">
            {t('common:shopPage.send-tax')}
          </td>
          <td className="text-right" colspan="3">
            10€
          </td>
        </tr>
        <tr className="">
          <td className="text-right" colspan="3">
            {t('common:shopPage.vat')} 23%{' '}
          </td>
          <td className="text-right" colspan="3">
            {getTaxes()}€
          </td>
        </tr>
        <tr>
          <td className="text-right" colspan="3">
            <strong>{t('common:shopPage.total')}:</strong>
          </td>
          <td className="text-right table-success" colspan="3">
            <strong>{subtotal + getTaxes() + 10}€</strong>
          </td>
        </tr>
      </tbody>
    );
  } else {
    cartItems = (
      <tr className="text-center">
        <td colspan="5">
          <strong>{t('common:shopPage.products-table.no-products')}</strong>
        </td>
      </tr>
    );
  }

  return (
    <>
      <HomeShop />
      <div className="container shadow p-5 mb-5 bg-body rounded w-75 border">
        <form class="needs-validation" novalidate>
          <div className="row p-4 border m-4">
            <div class="row">
              <div class="col-6">
                <label for="firstName" class="form-label">
                  {t('common:shopPage.checkout-form.name')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.name')}
                  aria-label="Nome"
                  id="firstName"
                  name="first_name"
                  onChange={handleChange}
                />
              </div>
              <div class="col-6">
                <label for="lastName" class="form-label">
                  {t('common:shopPage.checkout-form.last-name')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.last-name')}
                  aria-label="Last name"
                  id="lastName"
                  name="last_name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row p-4">
              <div class="col-6">
                <label for="company" class="form-label">
                  {t('common:shopPage.checkout-form.entreprise-name')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t(
                    'common:shopPage.checkout-form.entreprise-name',
                  )}
                  aria-label="Last name"
                  id="company"
                  name="company"
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <label for="country" class="form-label">
                  {t('common:shopPage.checkout-form.country')}
                </label>
                <CountryDropdown
                  value={orderData.country}
                  onChange={(val) => handleChange(val)}
                  name="country"
                  id="country"
                  className="form-select"
                  defaultOptionLabel="Please select your country"
                />
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <label for="street" class="form-label">
                  {t('common:shopPage.checkout-form.street')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.street')}
                  aria-label="Last name"
                  id="street"
                  name="address_1"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-4">
                <label for="city" class="form-label">
                  {t('common:shopPage.checkout-form.city')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.city')}
                  aria-label="Last name"
                  id="city"
                  name="city"
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <label for="postcode" class="form-label">
                  {t('common:shopPage.checkout-form.cp')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.cp')}
                  aria-label="Zip"
                  id="postcode"
                  name="postcode"
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <label for="vat" class="form-label">
                  {t('common:shopPage.checkout-form.vat')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.vat')}
                  aria-label="Zip"
                  id="vat"
                  name="vat"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-6">
                <label for="phone" class="form-label">
                  {t('common:shopPage.checkout-form.phone')}
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.phone')}
                  aria-label="phone"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <label for="phone" class="form-label">
                  {t('common:shopPage.checkout-form.email')}
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder={t('common:shopPage.checkout-form.email')}
                  aria-label="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  required="true"
                />
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-6">
                <label for="notes" class="form-label">
                  {t('common:shopPage.checkout-form.notes')}
                </label>
                <textarea
                  class="form-control"
                  id="notes"
                  rows="2"
                  name="customer_note"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </form>
        <div className="row p-4 border m-4">
          <div class="table-responsible">
            <table class="table table-hover table-borderless">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col">
                    {t('common:shopPage.products-table.header1')}
                  </th>
                  <th scope="col">
                    {t('common:shopPage.products-table.header2')}
                  </th>
                  <th scope="col">
                    {t('common:shopPage.products-table.header3')}
                  </th>
                  <th scope="col">
                    {t('common:shopPage.products-table.header4')}
                  </th>
                </tr>
              </thead>
              {items.length > 0 ? cartItems : <tbody>{cartItems}</tbody>}
            </table>
          </div>
        </div>
        <div className="row p-4 border m-4">
          <div className="row pt-4">
            <div class="alert alert-primary" role="alert">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="checkbox1"
                  disabled={checkBox1}
                  onChange={(event) => handleRadioChange(event)}
                />
                <label class="checkbox1" for="checkbox1">
                  <strong>
                    {t('common:shopPage.payment-boxes.box1.title')}
                  </strong>
                </label>
              </div>
              {t('common:shopPage.payment-boxes.box1.text')}
            </div>
          </div>
          <div className="row pt-4">
            <div class="alert alert-warning" role="alert">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="checkbox2"
                  disabled={checkBox2}
                  onChange={(event) => handleRadioChange(event)}
                />
                <label class="checkbox2" for="checkbox2">
                  <strong>
                    {t('common:shopPage.payment-boxes.box2.title')}
                  </strong>
                </label>
              </div>
              {t('common:shopPage.payment-boxes.box2.text')}
            </div>
          </div>
          <div className="row pt-4">
            <div class="alert alert-info" role="alert">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="checkbox3"
                  onChange={(event) => handleRadioChange(event)}
                />
                <label class="checkbox1" for="checkbox1">
                  <strong>
                    {t('common:shopPage.payment-boxes.box3.title')}
                  </strong>
                </label>
              </div>
              {t('common:shopPage.payment-boxes.box3.text')}
            </div>
          </div>
        </div>
        <div className="row p-4 border m-4 ">
          <div className="col-12 text-center">
            <button
              type="submit"
              class="btn btn-primary"
              onClick={handleSubmit}
            >
              {t('common:shopPage.payment-boxes.submit-button')}
            </button>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Checkoutv1;
