import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Context } from '../../context/DataContext';
import Cart from '../Cart/Cart';
import Footer from '../Footer';
import HomeShop from '../Shop/HomeShop';
import NavbarShop from '../Shop/NavbarShop';
import './Product.css';

const Product = () => {
  const { t } = useTranslation(['common']);
  const { addToCart } = useContext(Context);
  let history = useHistory();
  function handleContinueShop() {
    history.push({
      pathname: `/loja`,
    });
  }
  return (
    <>
      <NavbarShop />
      <HomeShop />
      <div className="container p-6">
        <div className="row py-4 px-2">
          <div className="col-md-4 col-sm-6 py-3">
            <div className="row">
              <div className="col-10 mx-auto">
                <img
                  src={history.location.state.product.images[0].src}
                  alt={history.location.state.product.name}
                  className="img-thumbnail"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 my-auto py-3">
            <div className="row text-center">
              <h4 className="productTitle">
                {history.location.state.product.name}
              </h4>
            </div>
            <div className="row text-center">
              <h4 className="productTitle">
                {history.location.state.product.price}
                <span className="currency">€</span>
              </h4>
            </div>
            <div className="row text-center">
              <h4 className="productTitle">
                {' '}
                {history.location.state.product.short_description.replace(
                  /(<([^>]+)>)/gi,
                  '',
                )}
              </h4>
            </div>
            <div className="row text-center">
              <div className="col-12">
                <button
                  className="btn btn-primary"
                  onClick={(event) => {
                    addToCart(history.location.state.product);
                  }}
                >
                  {t('common:shopPage.button-add-to-cart')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 shadow p-3 mb-5 bg-body rounded mt-sm-3 mt-md-3">
            <Cart />
          </div>
        </div>
        <div className="row py-4 px-2 justify-content-center">
          <div className="col-md-6 text-center">
            <button
              className="btn btn-primary"
              onClick={(event) => handleContinueShop()}
            >
              {t('common:shopPage.continue-shopping')}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Product;
