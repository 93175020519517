import { RiCheckboxCircleLine } from "react-icons/ri";
const yes = <RiCheckboxCircleLine id="true" size="20px" color="green" />;

const data_en = () => {
	return [
		{
			id: 1,
			title: "5’S E GESTÃO VISUAL",
			category1: "Formación",
			category2: "Automóvil",
			category3: "Lean/Productividad",
			opco: true,
			description:
				"Los 5'S y la Gestión Visual son técnicas basadas en la mejora continua. Los 5'S involucran a todos los que trabajan en la organización y que, con regla y disciplina, promueven el flujo de materiales, mejoran las condiciones de trabajo, el medio ambiente y la seguridad.",
			objectives:
				"Dotar a los aprendices de las habilidades necesarias para la operacionalización de herramientas que permitan garantizar un sistema continuo de 5`S y gestión visual del lugar de trabajo y la organización, con el objetivo de mejorar la calidad y la productividad.",
			online: yes,
			presential: yes,
			online_hours: "4",
			presential_hours: "16",
			associated_training: ["5’S – Programa facilitador", "5’S – Conciencia"],
		},
	];
};

export default data_en;
