import React from 'react';
import Image from 'react-image-webp';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Swal from 'sweetalert2';

import img1 from '../../images/brands/1.jpg';
import img1w from '../../images/brands/1.webp';
import img2 from '../../images/brands/2.jpg';
import img2w from '../../images/brands/2.webp';
import img3 from '../../images/brands/3.png';
import img3w from '../../images/brands/3.webp';
import img4 from '../../images/brands/4.jpg';
import img4w from '../../images/brands/4.webp';
import img5 from '../../images/brands/5.jpg';
import img5w from '../../images/brands/5.webp';
import img6 from '../../images/brands/6.png';
import img6w from '../../images/brands/6.webp';
import img7 from '../../images/brands/7.jpg';
import img7w from '../../images/brands/7.webp';
import img8 from '../../images/brands/8.jpg';
import img8w from '../../images/brands/8.webp';
import img9 from '../../images/brands/9.png';
import img9w from '../../images/brands/9.webp';
import img10 from '../../images/brands/10.jpg';
import img10w from '../../images/brands/10.webp';
import img11 from '../../images/brands/11.png';
import img11w from '../../images/brands/11.webp';
import img12 from '../../images/brands/12.png';
import img12w from '../../images/brands/12.webp';
import img13 from '../../images/brands/13.png';
import img13w from '../../images/brands/13.webp';
import img14 from '../../images/brands/14.png';
import img14w from '../../images/brands/14.webp';
import img15 from '../../images/brands/15.png';
import img15w from '../../images/brands/15.webp';
import img16 from '../../images/brands/16.png';
import img16w from '../../images/brands/16.webp';
import img17 from '../../images/brands/17.png';
import img17w from '../../images/brands/17.webp';
import img18 from '../../images/brands/18.png';
import img18w from '../../images/brands/18.webp';
import img19 from '../../images/brands/19.png';
import img19w from '../../images/brands/19.webp';
import img20 from '../../images/brands/20.png';
import img20w from '../../images/brands/20.webp';
import img21 from '../../images/brands/21.jpg';
import img21w from '../../images/brands/21.webp';
import img22 from '../../images/brands/22.png';
import img25 from '../../images/brands/22.png';
import img22w from '../../images/brands/22.webp';
import img23 from '../../images/brands/23.png';
import img23w from '../../images/brands/23.webp';
import img24 from '../../images/brands/24.png';
import img24w from '../../images/brands/24.webp';
import img25w from '../../images/brands/25.webp';
import img26 from '../../images/brands/26.png';
import img26w from '../../images/brands/26.webp';
import img27 from '../../images/brands/27.png';
import img27w from '../../images/brands/27.webp';
import img28 from '../../images/brands/28.jpg';
import img28w from '../../images/brands/28.webp';
import img29 from '../../images/brands/29.png';
import img29w from '../../images/brands/29.webp';
import img30 from '../../images/brands/30.png';
import img30w from '../../images/brands/30.webp';
import img31 from '../../images/brands/31.png';
import img31w from '../../images/brands/31.webp';
import img32 from '../../images/brands/32.png';
import img32w from '../../images/brands/32.webp';
import img33 from '../../images/brands/33.png';
import img33w from '../../images/brands/33.webp';
import img34 from '../../images/brands/34.png';
import img34w from '../../images/brands/34.webp';
import img35 from '../../images/brands/35.png';
import img35w from '../../images/brands/35.webp';
import img36 from '../../images/brands/36.png';
import img36w from '../../images/brands/36.webp';
import img37 from '../../images/brands/37.png';
import img37w from '../../images/brands/37.webp';
import img38 from '../../images/brands/38.png';
import img38w from '../../images/brands/38.webp';
import img39 from '../../images/brands/39.png';
import img39w from '../../images/brands/39.webp';
import img40 from '../../images/brands/40.png';
import img40w from '../../images/brands/40.webp';
import img41 from '../../images/brands/41.png';
import img41w from '../../images/brands/41.webp';
import img42 from '../../images/brands/42.png';
import img42w from '../../images/brands/42.webp';
import img43 from '../../images/brands/43.jpg';
import img43w from '../../images/brands/43.webp';
import img44 from '../../images/brands/44.png';
import img44w from '../../images/brands/44.webp';
import img45 from '../../images/brands/45.jpg';
import img45w from '../../images/brands/45.webp';
import img46 from '../../images/brands/46.png';
import img46w from '../../images/brands/46.webp';
import img47 from '../../images/brands/47.jpg';
import img47w from '../../images/brands/47.webp';
import img48 from '../../images/brands/48.png';
import img48w from '../../images/brands/48.webp';
import img49 from '../../images/brands/49.png';
import img49w from '../../images/brands/49.webp';
import img50 from '../../images/brands/50.png';
import img50w from '../../images/brands/50.webp';
import img51 from '../../images/brands/51.png';
import img51w from '../../images/brands/51.webp';
import img52 from '../../images/brands/52.jpg';
import img52w from '../../images/brands/52.webp';
import img53 from '../../images/brands/53.jpg';
import img53w from '../../images/brands/53.webp';
import img54 from '../../images/brands/54.jpg';
import img54w from '../../images/brands/54.webp';
import img55 from '../../images/brands/55.png';
import img55w from '../../images/brands/55.webp';
import img56 from '../../images/brands/56.png';
import img56w from '../../images/brands/56.webp';
import img57 from '../../images/brands/57.png';
import img57w from '../../images/brands/57.webp';
import img58 from '../../images/brands/58.png';
import img58w from '../../images/brands/58.webp';
import img59 from '../../images/brands/59.png';
import img59w from '../../images/brands/59.webp';
import img60 from '../../images/brands/60.png';
import img60w from '../../images/brands/60.webp';
import img61 from '../../images/brands/61.png';
import img61w from '../../images/brands/61.webp';
import img62 from '../../images/brands/62.png';
import img62w from '../../images/brands/62.webp';
import img63 from '../../images/brands/63.png';
import img63w from '../../images/brands/63.webp';
import img64 from '../../images/brands/64.png';
import img64w from '../../images/brands/64.webp';
import img65 from '../../images/brands/65.png';
import img65w from '../../images/brands/65.webp';
import img66 from '../../images/brands/66.png';
import img66w from '../../images/brands/66.webp';
import img67 from '../../images/brands/67.png';
import img67w from '../../images/brands/67.webp';
import img68 from '../../images/brands/68.png';
import img68w from '../../images/brands/68.webp';
import img69 from '../../images/brands/69.png';
import img69w from '../../images/brands/69.webp';
import img70 from '../../images/brands/70.png';
import img70w from '../../images/brands/70.webp';
import img71 from '../../images/brands/71.png';
import img71w from '../../images/brands/71.webp';
import img72 from '../../images/brands/72.jpg';
import img72w from '../../images/brands/72.webp';
import img73 from '../../images/brands/73.png';
import img73w from '../../images/brands/73.webp';
import img74 from '../../images/brands/74.png';
import img74w from '../../images/brands/74.webp';
import img75 from '../../images/brands/75.png';
import img75w from '../../images/brands/75.webp';
import img76 from '../../images/brands/76.jpg';
import img76w from '../../images/brands/76.webp';
import img77 from '../../images/brands/77.jpg';
import img77w from '../../images/brands/77.webp';
import img78 from '../../images/brands/78.png';
import img78w from '../../images/brands/78.webp';
import img79 from '../../images/brands/79.png';
import img79w from '../../images/brands/79.webp';
import img80 from '../../images/brands/80.jpg';
import img80w from '../../images/brands/80.webp';
import img81 from '../../images/brands/81.png';
import img81w from '../../images/brands/81.webp';
import img82 from '../../images/brands/82.png';
import img82w from '../../images/brands/82.webp';
import img83 from '../../images/brands/83.png';
import img83w from '../../images/brands/83.webp';
import img84 from '../../images/brands/84.png';
import img84w from '../../images/brands/84.webp';
import img85 from '../../images/brands/85.png';
import img85w from '../../images/brands/85.webp';
import img86 from '../../images/brands/86.png';
import img86w from '../../images/brands/86.webp';
import img87 from '../../images/brands/87.jpg';
import img87w from '../../images/brands/87.webp';
import img88 from '../../images/brands/88.png';
import img88w from '../../images/brands/88.webp';
import img89 from '../../images/brands/89.png';
import img89w from '../../images/brands/89.webp';
import img90 from '../../images/brands/90.png';
import img90w from '../../images/brands/90.webp';
import img91 from '../../images/brands/91.jpg';
import img91w from '../../images/brands/91.webp';
import img92 from '../../images/brands/92.jpg';
import img92w from '../../images/brands/92.webp';
import img93 from '../../images/brands/93.png';
import img93w from '../../images/brands/93.webp';
import img94 from '../../images/brands/94.png';
import img94w from '../../images/brands/94.webp';
import img95 from '../../images/brands/95.png';
import img95w from '../../images/brands/95.webp';
import img96 from '../../images/brands/96.png';
import img96w from '../../images/brands/96.webp';
import img97 from '../../images/brands/97.png';
import img97w from '../../images/brands/97.webp';
import img98 from '../../images/brands/98.png';
import img98w from '../../images/brands/98.webp';
import img99 from '../../images/brands/99.png';
import img99w from '../../images/brands/99.webp';
import img100 from '../../images/brands/100.png';
import img100w from '../../images/brands/100.webp';
import img101 from '../../images/brands/101.png';
import img101w from '../../images/brands/101.webp';
import img102 from '../../images/brands/102.jpg';
import img102w from '../../images/brands/102.webp';
import img103 from '../../images/brands/103.png';
import img103w from '../../images/brands/103.webp';
import img104 from '../../images/brands/104.png';
import img104w from '../../images/brands/104.webp';
import img105 from '../../images/brands/105.png';
import img105w from '../../images/brands/105.webp';
import img106 from '../../images/brands/106.jpg';
import img106w from '../../images/brands/106.webp';
import img107 from '../../images/brands/107.jpg';
import img107w from '../../images/brands/107.webp';
import img108 from '../../images/brands/108.jpg';
import img108w from '../../images/brands/108.webp';
import img109 from '../../images/brands/109.jpg';
import img109w from '../../images/brands/109.webp';
import img110 from '../../images/brands/110.png';
import img110w from '../../images/brands/110.webp';
import img111 from '../../images/brands/111.png';
import img111w from '../../images/brands/111.webp';
import img112 from '../../images/brands/112.png';
import img112w from '../../images/brands/112.webp';
import img113 from '../../images/brands/113.png';
import img113w from '../../images/brands/113.webp';
import img114 from '../../images/brands/114.png';
import img114w from '../../images/brands/114.webp';
import img115 from '../../images/brands/115.png';
import img115w from '../../images/brands/115.webp';
import img116 from '../../images/brands/116.png';
import img116w from '../../images/brands/116.webp';
import img117 from '../../images/brands/117.png';
import img117w from '../../images/brands/117.webp';
import img118 from '../../images/brands/118.png';
import img118w from '../../images/brands/118.webp';
import img119 from '../../images/brands/119.png';
import img119w from '../../images/brands/119.webp';
import img120 from '../../images/brands/120.jpg';
import img120w from '../../images/brands/120.webp';
import img121 from '../../images/brands/121.png';
import img121w from '../../images/brands/121.webp';
import img122 from '../../images/brands/122.jpg';
import img122w from '../../images/brands/122.webp';
import img123 from '../../images/brands/123.png';
import img123w from '../../images/brands/123.webp';
import img124 from '../../images/brands/124.png';
import img124w from '../../images/brands/124.webp';
import img125 from '../../images/brands/125.jpg';
import img125w from '../../images/brands/125.webp';
import img126 from '../../images/brands/126.jpg';
import img126w from '../../images/brands/126.webp';
import img127 from '../../images/brands/127.png';
import img127w from '../../images/brands/127.webp';
import img128 from '../../images/brands/128.png';
import img128w from '../../images/brands/128.webp';
import img129 from '../../images/brands/129.png';
import img129w from '../../images/brands/129.webp';
import img130 from '../../images/brands/130.jpg';
import img130w from '../../images/brands/130.webp';
import img131 from '../../images/brands/131.png';
import img131w from '../../images/brands/131.webp';
import img132 from '../../images/brands/132.jpg';
import img132w from '../../images/brands/132.webp';
import img133 from '../../images/brands/133.jpg';
import img133w from '../../images/brands/133.webp';
import img134 from '../../images/brands/134.png';
import img134w from '../../images/brands/134.webp';
import img135 from '../../images/brands/135.png';
import img135w from '../../images/brands/135.webp';
import img136 from '../../images/brands/136.jpg';
import img136w from '../../images/brands/136.webp';
import img137 from '../../images/brands/137.jpg';
import img137w from '../../images/brands/137.webp';
import img138 from '../../images/brands/138.png';
import img138w from '../../images/brands/138.webp';
import img139 from '../../images/brands/139.jpg';
import img139w from '../../images/brands/139.webp';
import img140 from '../../images/brands/140.jpg';
import img140w from '../../images/brands/140.webp';
import img141 from '../../images/brands/141.jpg';
import img141w from '../../images/brands/141.webp';
import img142 from '../../images/brands/142.jpg';
import img142w from '../../images/brands/142.webp';
import img143 from '../../images/brands/143.png';
import img143w from '../../images/brands/143.webp';
import img144 from '../../images/brands/144.png';
import img144w from '../../images/brands/144.webp';
import img145 from '../../images/brands/145.png';
import img145w from '../../images/brands/145.webp';
import img146 from '../../images/brands/146.png';
import img146w from '../../images/brands/146.webp';
import img147 from '../../images/brands/147.jpg';
import img147w from '../../images/brands/147.webp';
import img148 from '../../images/brands/148.png';
import img148w from '../../images/brands/148.webp';
import img149 from '../../images/brands/149.png';
import img149w from '../../images/brands/149.webp';
import img150 from '../../images/brands/150.png';
import img150w from '../../images/brands/150.webp';
import img151 from '../../images/brands/151.jpg';
import img151w from '../../images/brands/151.webp';
import img152 from '../../images/brands/152.png';
import img152w from '../../images/brands/152.webp';
import img153 from '../../images/brands/153.jpg';
import img153w from '../../images/brands/153.webp';
import img154 from '../../images/brands/154.jpg';
import img154w from '../../images/brands/154.webp';
import img155 from '../../images/brands/155.png';
import img155w from '../../images/brands/155.webp';
import img156 from '../../images/brands/156.jpg';
import img156w from '../../images/brands/156.webp';
import img157 from '../../images/brands/157.jpg';
import img158 from '../../images/brands/158.png';
import img158w from '../../images/brands/158.webp';
import img159 from '../../images/brands/159.jpg';
import img159w from '../../images/brands/159.webp';
import img160 from '../../images/brands/160.jpg';
import img160w from '../../images/brands/160.webp';
import img161 from '../../images/brands/161.png';
import img161w from '../../images/brands/161.webp';
import img162 from '../../images/brands/162.png';
import img162w from '../../images/brands/162.webp';
import img163 from '../../images/brands/163.png';
import img163w from '../../images/brands/163.webp';
import img164 from '../../images/brands/164.png';
import img164w from '../../images/brands/164.webp';
import img165 from '../../images/brands/165.png';
import img165w from '../../images/brands/165.webp';
import img166 from '../../images/brands/166.jpg';
import img166w from '../../images/brands/166.webp';
import img167 from '../../images/brands/167.png';
import img167w from '../../images/brands/167.webp';
import partner8 from '../../images/partners/aiag.jpg';
import partner8webp from '../../images/partners/aiag.webp';
import partner1 from '../../images/partners/fiev.jpg';
import partner1webp from '../../images/partners/fiev.webp';
import partner2 from '../../images/partners/odette.jpg';
import partner2webp from '../../images/partners/odette.webp';
import partner3 from '../../images/partners/plexus.jpg';
import partner3webp from '../../images/partners/plexus.webp';
import partner6 from '../../images/partners/smmt.jpg';
import partner6webp from '../../images/partners/smmt.webp';
import partner9 from '../../images/partners/stellanntis.jpeg';
import partner9webp from '../../images/partners/stellanntis.webp';
import partner7 from '../../images/partners/vda.jpeg';
import partner7webp from '../../images/partners/vda.webp';
import './GalleryElements.css';

const handleDragStart = (e) => {
  e.preventDefault();
};

const handleClick = (e) => {
  e.preventDefault();
  Modal(e.target.alt);
};

const items = [
  <Image
    src={img1}
    webp={img1w}
    onDragStart={handleDragStart}
    alt="img-1"
    className="brand_logo"
  />,
  <Image
    src={img2}
    webp={img2w}
    onDragStart={handleDragStart}
    alt="img-2"
    className="brand_logo"
  />,
  <Image
    src={img3}
    webp={img3w}
    onDragStart={handleDragStart}
    alt="img-3"
    className="brand_logo"
  />,
  <Image
    src={img4}
    webp={img4w}
    onDragStart={handleDragStart}
    alt="img-4"
    className="brand_logo"
  />,
  <Image
    src={img5}
    webp={img5w}
    onDragStart={handleDragStart}
    alt="img-5"
    className="brand_logo"
  />,
  <Image
    src={img6}
    webp={img6w}
    onDragStart={handleDragStart}
    alt="img-6"
    className="brand_logo"
  />,
  <Image
    src={img7}
    webp={img7w}
    onDragStart={handleDragStart}
    alt="img-7"
    className="brand_logo"
  />,
  <Image
    src={img8}
    webp={img8w}
    onDragStart={handleDragStart}
    alt="img-8"
    className="brand_logo"
  />,
  <Image
    src={img9}
    webp={img9w}
    onDragStart={handleDragStart}
    alt="img-9"
    className="brand_logo"
  />,
  <Image
    src={img10}
    webp={img10w}
    onDragStart={handleDragStart}
    alt="img-10"
    className="brand_logo"
  />,
  <Image
    src={img11}
    webp={img11w}
    onDragStart={handleDragStart}
    alt="img-11"
    className="brand_logo"
  />,
  <Image
    src={img12}
    webp={img12w}
    onDragStart={handleDragStart}
    alt="img-12"
    className="brand_logo"
  />,
  <Image
    src={img13}
    webp={img13w}
    onDragStart={handleDragStart}
    alt="img-13"
    className="brand_logo"
  />,
  <Image
    src={img14}
    webp={img14w}
    onDragStart={handleDragStart}
    alt="img-14"
    className="brand_logo"
  />,
  <Image
    src={img15}
    webp={img15w}
    onDragStart={handleDragStart}
    alt="img-15"
    className="brand_logo"
  />,
  <Image
    src={img16}
    webp={img16w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img17}
    webp={img17w}
    onDragStart={handleDragStart}
    alt="img-17"
    className="brand_logo"
  />,
  <Image
    src={img18}
    webp={img18w}
    onDragStart={handleDragStart}
    alt="img-18"
    className="brand_logo"
  />,
  <Image
    src={img19}
    webp={img19w}
    onDragStart={handleDragStart}
    alt="img-19"
    className="brand_logo"
  />,
  <Image
    src={img20}
    webp={img20w}
    onDragStart={handleDragStart}
    alt="img-20"
    className="brand_logo"
  />,
  <Image
    src={img21}
    webp={img21w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img22}
    webp={img22w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img23}
    webp={img23w}
    onDragStart={handleDragStart}
    alt="img-23"
    className="brand_logo"
  />,
  <Image
    src={img24}
    webp={img24w}
    onDragStart={handleDragStart}
    alt="img-24"
    className="brand_logo"
  />,
  <Image
    src={img25}
    webp={img25w}
    onDragStart={handleDragStart}
    alt="img-25"
    className="brand_logo"
  />,
  <Image
    src={img26}
    webp={img26w}
    onDragStart={handleDragStart}
    alt="img-26"
    className="brand_logo"
  />,
  <Image
    src={img27}
    webp={img27w}
    onDragStart={handleDragStart}
    alt="img-27"
    className="brand_logo"
  />,
  <Image
    src={img28}
    webp={img28w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img29}
    webp={img29w}
    onDragStart={handleDragStart}
    alt="img-29"
    className="brand_logo"
  />,
  <Image
    src={img30}
    webp={img30w}
    onDragStart={handleDragStart}
    alt="img-30"
    className="brand_logo"
  />,
  <Image
    src={img31}
    webp={img31w}
    onDragStart={handleDragStart}
    alt="img-31"
    className="brand_logo"
  />,
  <Image
    src={img32}
    webp={img32w}
    onDragStart={handleDragStart}
    alt="img-32"
    className="brand_logo"
  />,
  <Image
    src={img33}
    webp={img33w}
    onDragStart={handleDragStart}
    alt="img-33"
    className="brand_logo"
  />,
  <Image
    src={img34}
    webp={img34w}
    onDragStart={handleDragStart}
    alt="img-34"
    className="brand_logo"
  />,
  <Image
    src={img35}
    webp={img35w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img36}
    webp={img36w}
    onDragStart={handleDragStart}
    alt="img-36"
    className="brand_logo"
  />,
  <Image
    src={img37}
    webp={img37w}
    onDragStart={handleDragStart}
    alt="img-37"
    className="brand_logo"
  />,
  <Image
    src={img38}
    webp={img38w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img39}
    webp={img39w}
    onDragStart={handleDragStart}
    alt="img-16"
    className="brand_logo"
  />,
  <Image
    src={img40}
    webp={img40w}
    onDragStart={handleDragStart}
    alt="img-40"
    className="brand_logo"
  />,
  <Image
    src={img41}
    webp={img41w}
    onDragStart={handleDragStart}
    alt="img-41"
    className="brand_logo"
  />,
  <Image
    src={img42}
    webp={img42w}
    onDragStart={handleDragStart}
    alt="img-42"
    className="brand_logo"
  />,
  <Image
    src={img43}
    webp={img43w}
    onDragStart={handleDragStart}
    alt="img-43"
    className="brand_logo"
  />,
  <Image
    src={img44}
    webp={img44w}
    onDragStart={handleDragStart}
    alt="img-44"
    className="brand_logo"
  />,
  <Image
    src={img45}
    webp={img45w}
    onDragStart={handleDragStart}
    alt="img-45"
    className="brand_logo"
  />,
  <Image
    src={img46}
    webp={img46w}
    onDragStart={handleDragStart}
    alt="img-46"
    className="brand_logo"
  />,
  <Image
    src={img47}
    webp={img47w}
    onDragStart={handleDragStart}
    alt="img-47"
    className="brand_logo"
  />,
  <Image
    src={img48}
    webp={img48w}
    onDragStart={handleDragStart}
    alt="img-48"
    className="brand_logo"
  />,
  <Image
    src={img49}
    webp={img49w}
    onDragStart={handleDragStart}
    alt="img-49"
    className="brand_logo"
  />,
  <Image
    src={img50}
    webp={img50w}
    onDragStart={handleDragStart}
    alt="img-50"
    className="brand_logo"
  />,
  <Image
    src={img51}
    webp={img51w}
    onDragStart={handleDragStart}
    alt="img-51"
    className="brand_logo"
  />,
  <Image
    src={img52}
    webp={img52w}
    onDragStart={handleDragStart}
    alt="img-52"
    className="brand_logo"
  />,
  <Image
    src={img53}
    webp={img53w}
    onDragStart={handleDragStart}
    alt="img-53"
    className="brand_logo"
  />,
  <Image
    src={img54}
    webp={img54w}
    onDragStart={handleDragStart}
    alt="img-44"
    className="brand_logo"
  />,
  <Image
    src={img55}
    webp={img55w}
    onDragStart={handleDragStart}
    alt="img-55"
    className="brand_logo"
  />,
  <Image
    src={img56}
    webp={img56w}
    onDragStart={handleDragStart}
    alt="img-56"
    className="brand_logo"
  />,
  <Image
    src={img57}
    webp={img57w}
    onDragStart={handleDragStart}
    alt="img-57"
    className="brand_logo"
  />,
  <Image
    src={img58}
    webp={img58w}
    onDragStart={handleDragStart}
    alt="img-48"
    className="brand_logo"
  />,
  <Image
    src={img59}
    webp={img59w}
    onDragStart={handleDragStart}
    alt="img-59"
    className="brand_logo"
  />,
  <Image
    src={img60}
    webp={img60w}
    onDragStart={handleDragStart}
    alt="img-60"
    className="brand_logo"
  />,
  <Image
    src={img61}
    webp={img61w}
    onDragStart={handleDragStart}
    alt="img-61"
    className="brand_logo"
  />,
  <Image
    src={img62}
    webp={img62w}
    onDragStart={handleDragStart}
    alt="img-62"
    className="brand_logo"
  />,
  <Image
    src={img63}
    webp={img63w}
    onDragStart={handleDragStart}
    alt="img-63"
    className="brand_logo"
  />,
  <Image
    src={img64}
    webp={img64w}
    onDragStart={handleDragStart}
    alt="img-64"
    className="brand_logo"
  />,
  <Image
    src={img65}
    webp={img65w}
    onDragStart={handleDragStart}
    alt="img-65"
    className="brand_logo"
  />,
  <Image
    src={img66}
    webp={img66w}
    onDragStart={handleDragStart}
    alt="img-66"
    className="brand_logo"
  />,
  <Image
    src={img67}
    webp={img67w}
    onDragStart={handleDragStart}
    alt="img-67"
    className="brand_logo"
  />,
  <Image
    src={img68}
    webp={img68w}
    onDragStart={handleDragStart}
    alt="img-68"
    className="brand_logo"
  />,
  <Image
    src={img69}
    webp={img69w}
    onDragStart={handleDragStart}
    alt="img-69"
    className="brand_logo"
  />,
  <Image
    src={img70}
    webp={img70w}
    onDragStart={handleDragStart}
    alt="img-70"
    className="brand_logo"
  />,
  <Image
    src={img71}
    webp={img71w}
    onDragStart={handleDragStart}
    alt="img-71"
    className="brand_logo"
  />,

  <Image
    src={img72}
    webp={img72w}
    onDragStart={handleDragStart}
    alt="img-72"
    className="brand_logo"
  />,
  <Image
    src={img73}
    webp={img73w}
    onDragStart={handleDragStart}
    alt="img-73"
    className="brand_logo"
  />,
  <Image
    src={img74}
    webp={img74w}
    onDragStart={handleDragStart}
    alt="img-74"
    className="brand_logo"
  />,
  <Image
    src={img75}
    webp={img75w}
    onDragStart={handleDragStart}
    alt="img-75"
    className="brand_logo"
  />,
  <Image
    src={img76}
    webp={img76w}
    onDragStart={handleDragStart}
    alt="img-76"
    className="brand_logo"
  />,
  <Image
    src={img77}
    webp={img77w}
    onDragStart={handleDragStart}
    alt="img-77"
    className="brand_logo"
  />,
  <Image
    src={img78}
    webp={img78w}
    onDragStart={handleDragStart}
    alt="img-78"
    className="brand_logo"
  />,
  <Image
    src={img79}
    webp={img79w}
    onDragStart={handleDragStart}
    alt="img-79"
    className="brand_logo"
  />,
  <Image
    src={img80}
    webp={img80w}
    onDragStart={handleDragStart}
    alt="img-80"
    className="brand_logo"
  />,
  <Image
    src={img81}
    webp={img81w}
    onDragStart={handleDragStart}
    alt="img-81"
    className="brand_logo"
  />,
  <Image
    src={img82}
    webp={img82w}
    onDragStart={handleDragStart}
    alt="img-82"
    className="brand_logo"
  />,
  <Image
    src={img83}
    webp={img83w}
    onDragStart={handleDragStart}
    alt="img-83"
    className="brand_logo"
  />,
  <Image
    src={img84}
    webp={img84w}
    onDragStart={handleDragStart}
    alt="img-84"
    className="brand_logo"
  />,
  <Image
    src={img85}
    webp={img85w}
    onDragStart={handleDragStart}
    alt="img-85"
    className="brand_logo"
  />,
  <Image
    src={img86}
    webp={img86w}
    onDragStart={handleDragStart}
    alt="img-86"
    className="brand_logo"
  />,
  <Image
    src={img87}
    webp={img87w}
    onDragStart={handleDragStart}
    alt="img-87"
    className="brand_logo"
  />,
  <Image
    src={img88}
    webp={img88w}
    onDragStart={handleDragStart}
    alt="img-88"
    className="brand_logo"
  />,
  <Image
    src={img89}
    webp={img89w}
    onDragStart={handleDragStart}
    alt="img-79"
    className="brand_logo"
  />,
  <Image
    src={img90}
    webp={img90w}
    onDragStart={handleDragStart}
    alt="img-90"
    className="brand_logo"
  />,
  <Image
    src={img91}
    webp={img91w}
    onDragStart={handleDragStart}
    alt="img-81"
    className="brand_logo"
  />,
  <Image
    src={img92}
    webp={img92w}
    onDragStart={handleDragStart}
    alt="img-92"
    className="brand_logo"
  />,
  <Image
    src={img93}
    webp={img93w}
    onDragStart={handleDragStart}
    alt="img-93"
    className="brand_logo"
  />,
  <Image
    src={img94}
    webp={img94w}
    onDragStart={handleDragStart}
    alt="img-94"
    className="brand_logo"
  />,
  <Image
    src={img95}
    webp={img95w}
    onDragStart={handleDragStart}
    alt="img-95"
    className="brand_logo"
  />,
  <Image
    src={img96}
    webp={img96w}
    onDragStart={handleDragStart}
    alt="img-96"
    className="brand_logo"
  />,
  <Image
    src={img97}
    webp={img97w}
    onDragStart={handleDragStart}
    alt="img-97"
    className="brand_logo"
  />,
  <Image
    src={img98}
    webp={img98w}
    onDragStart={handleDragStart}
    alt="img-98"
    className="brand_logo"
  />,
  <Image
    src={img99}
    webp={img99w}
    onDragStart={handleDragStart}
    alt="img-99"
    className="brand_logo"
  />,
  <Image
    src={img100}
    webp={img100w}
    onDragStart={handleDragStart}
    alt="img-100"
    className="brand_logo"
  />,
  <Image
    src={img101}
    webp={img101w}
    onDragStart={handleDragStart}
    alt="img-101"
    className="brand_logo"
  />,
  <Image
    src={img102}
    webp={img102w}
    onDragStart={handleDragStart}
    alt="img-102"
    className="brand_logo"
  />,
  <Image
    src={img103}
    webp={img103w}
    onDragStart={handleDragStart}
    alt="img-103"
    className="brand_logo"
  />,
  <Image
    src={img104}
    webp={img104w}
    onDragStart={handleDragStart}
    alt="img-104"
    className="brand_logo"
  />,
  <Image
    src={img105}
    webp={img105w}
    onDragStart={handleDragStart}
    alt="img-105"
    className="brand_logo"
  />,
  <Image
    src={img106}
    webp={img106w}
    onDragStart={handleDragStart}
    alt="img-106"
    className="brand_logo"
  />,
  <Image
    src={img107}
    webp={img107w}
    onDragStart={handleDragStart}
    alt="img-107"
    className="brand_logo"
  />,
  <Image
    src={img108}
    webp={img108w}
    onDragStart={handleDragStart}
    alt="img-108"
    className="brand_logo"
  />,
  <Image
    src={img109}
    webp={img109w}
    onDragStart={handleDragStart}
    alt="img-109"
    className="brand_logo"
  />,
  <Image
    src={img110}
    webp={img110w}
    onDragStart={handleDragStart}
    alt="img-110"
    className="brand_logo"
  />,
  <Image
    src={img111}
    webp={img111w}
    onDragStart={handleDragStart}
    alt="img-111"
    className="brand_logo"
  />,
  <Image
    src={img112}
    webp={img112w}
    onDragStart={handleDragStart}
    alt="img-112"
    className="brand_logo"
  />,
  <Image
    src={img113}
    webp={img113w}
    onDragStart={handleDragStart}
    alt="img-113"
    className="brand_logo"
  />,
  <Image
    src={img114}
    webp={img114w}
    onDragStart={handleDragStart}
    alt="img-114"
    className="brand_logo"
  />,
  <Image
    src={img115}
    webp={img115w}
    onDragStart={handleDragStart}
    alt="img-115"
    className="brand_logo"
  />,
  <Image
    src={img116}
    webp={img116w}
    onDragStart={handleDragStart}
    alt="img-116"
    className="brand_logo"
  />,
  <Image
    src={img117}
    webp={img117w}
    onDragStart={handleDragStart}
    alt="img-117"
    className="brand_logo"
  />,
  <Image
    src={img118}
    webp={img118w}
    onDragStart={handleDragStart}
    alt="img-118"
    className="brand_logo"
  />,
  <Image
    src={img119}
    webp={img119w}
    onDragStart={handleDragStart}
    alt="img-119"
    className="brand_logo"
  />,
  <Image
    src={img120}
    webp={img120w}
    onDragStart={handleDragStart}
    alt="img-120"
    className="brand_logo"
  />,
  <Image
    src={img121}
    webp={img121w}
    onDragStart={handleDragStart}
    alt="img-121"
    className="brand_logo"
  />,
  <Image
    src={img122}
    webp={img122w}
    onDragStart={handleDragStart}
    alt="img-122"
    className="brand_logo"
  />,
  <Image
    src={img123}
    webp={img123w}
    onDragStart={handleDragStart}
    alt="img-123"
    className="brand_logo"
  />,
  <Image
    src={img124}
    webp={img124w}
    onDragStart={handleDragStart}
    alt="img-124"
    className="brand_logo"
  />,
  <Image
    src={img125}
    webp={img125w}
    onDragStart={handleDragStart}
    alt="img-125"
    className="brand_logo"
  />,
  <Image
    src={img126}
    webp={img126w}
    onDragStart={handleDragStart}
    alt="img-126"
    className="brand_logo"
  />,
  <Image
    src={img127}
    webp={img127w}
    onDragStart={handleDragStart}
    alt="img-127"
    className="brand_logo"
  />,
  <Image
    src={img128}
    webp={img128w}
    onDragStart={handleDragStart}
    alt="img-128"
    className="brand_logo"
  />,
  <Image
    src={img129}
    webp={img129w}
    onDragStart={handleDragStart}
    alt="img-129"
    className="brand_logo"
  />,
  <Image
    src={img130}
    webp={img130w}
    onDragStart={handleDragStart}
    alt="img-130"
    className="brand_logo"
  />,
  <Image
    src={img131}
    webp={img131w}
    onDragStart={handleDragStart}
    alt="img-131"
    className="brand_logo"
  />,
  <Image
    src={img132}
    webp={img132w}
    onDragStart={handleDragStart}
    alt="img-132"
    className="brand_logo"
  />,
  <Image
    src={img133}
    webp={img133w}
    onDragStart={handleDragStart}
    alt="img-133"
    className="brand_logo"
  />,
  <Image
    src={img134}
    webp={img134w}
    onDragStart={handleDragStart}
    alt="img-134"
    className="brand_logo"
  />,
  <Image
    src={img135}
    webp={img135w}
    onDragStart={handleDragStart}
    alt="img-135"
    className="brand_logo"
  />,
  <Image
    src={img136}
    webp={img136w}
    onDragStart={handleDragStart}
    alt="img-136"
    className="brand_logo"
  />,
  <Image
    src={img137}
    webp={img137w}
    onDragStart={handleDragStart}
    alt="img-137"
    className="brand_logo"
  />,
  <Image
    src={img138}
    webp={img138w}
    onDragStart={handleDragStart}
    alt="img-138"
    className="brand_logo"
  />,
  <Image
    src={img139}
    webp={img139w}
    onDragStart={handleDragStart}
    alt="img-139"
    className="brand_logo"
  />,
  <Image
    src={img140}
    webp={img140w}
    onDragStart={handleDragStart}
    alt="img-140"
    className="brand_logo"
  />,
  <Image
    src={img141}
    webp={img141w}
    onDragStart={handleDragStart}
    alt="img-141"
    className="brand_logo"
  />,
  <Image
    src={img142}
    webp={img142w}
    onDragStart={handleDragStart}
    alt="img-142"
    className="brand_logo"
  />,
  <Image
    src={img143}
    webp={img143w}
    onDragStart={handleDragStart}
    alt="img-143"
    className="brand_logo"
  />,
  <Image
    src={img144}
    webp={img144w}
    onDragStart={handleDragStart}
    alt="img-144"
    className="brand_logo"
  />,
  <Image
    src={img145}
    webp={img145w}
    onDragStart={handleDragStart}
    alt="img-145"
    className="brand_logo"
  />,
  <Image
    src={img146}
    webp={img146w}
    onDragStart={handleDragStart}
    alt="img-146"
    className="brand_logo"
  />,
  <Image
    src={img147}
    webp={img147w}
    onDragStart={handleDragStart}
    alt="img-147"
    className="brand_logo"
  />,
  <Image
    src={img148}
    webp={img148w}
    onDragStart={handleDragStart}
    alt="img-148"
    className="brand_logo"
  />,
  <Image
    src={img149}
    webp={img149w}
    onDragStart={handleDragStart}
    alt="img-149"
    className="brand_logo"
  />,
  <Image
    src={img150}
    webp={img150w}
    onDragStart={handleDragStart}
    alt="img-150"
    className="brand_logo"
  />,
  <Image
    src={img151}
    webp={img151w}
    onDragStart={handleDragStart}
    alt="img-151"
    className="brand_logo"
  />,
  <Image
    src={img152}
    webp={img152w}
    onDragStart={handleDragStart}
    alt="img-152"
    className="brand_logo"
  />,
  <Image
    src={img153}
    webp={img153w}
    onDragStart={handleDragStart}
    alt="img-153"
    className="brand_logo"
  />,
  <Image
    src={img154}
    webp={img154w}
    onDragStart={handleDragStart}
    alt="img-154"
    className="brand_logo"
  />,
  <Image
    src={img155}
    webp={img155w}
    onDragStart={handleDragStart}
    alt="img-155"
    className="brand_logo"
  />,
  <Image
    src={img156}
    webp={img156w}
    onDragStart={handleDragStart}
    alt="img-156"
    className="brand_logo"
  />,
  <Image
    src={img157}
    webp={img57w}
    onDragStart={handleDragStart}
    alt="img-157"
    className="brand_logo"
  />,
  <Image
    src={img158}
    webp={img158w}
    onDragStart={handleDragStart}
    alt="img-158"
    className="brand_logo"
  />,
  <Image
    src={img159}
    webp={img159w}
    onDragStart={handleDragStart}
    alt="img-159"
    className="brand_logo"
  />,
  <Image
    src={img160}
    webp={img160w}
    onDragStart={handleDragStart}
    alt="img-160"
    className="brand_logo"
  />,
  <Image
    src={img161}
    webp={img161w}
    onDragStart={handleDragStart}
    alt="img-161"
    className="brand_logo"
  />,
  <Image
    src={img162}
    webp={img162w}
    onDragStart={handleDragStart}
    alt="img-162"
    className="brand_logo"
  />,
  <Image
    src={img163}
    webp={img163w}
    onDragStart={handleDragStart}
    alt="img-163"
    className="brand_logo"
  />,
  <Image
    src={img164}
    webp={img164w}
    onDragStart={handleDragStart}
    alt="img-164"
    className="brand_logo"
  />,
  <Image
    src={img165}
    webp={img165w}
    onDragStart={handleDragStart}
    alt="img-165"
    className="brand_logo"
  />,
  <Image
    src={img166}
    webp={img166w}
    onDragStart={handleDragStart}
    alt="img-166"
    className="brand_logo"
  />,
  <Image
    src={img167}
    webp={img167w}
    onDragStart={handleDragStart}
    alt="img-167"
    className="brand_logo"
  />,
];
const items2 = [
  <Image
    src={partner1}
    webp={partner1webp}
    onDragStart={handleDragStart}
    alt="0"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner2}
    webp={partner2webp}
    onDragStart={handleDragStart}
    alt="1"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner3}
    webp={partner3webp}
    onDragStart={handleDragStart}
    alt="2"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner6}
    webp={partner6webp}
    onDragStart={handleDragStart}
    alt="5"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner7}
    webp={partner7webp}
    onDragStart={handleDragStart}
    alt="6"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner8}
    webp={partner8webp}
    onDragStart={handleDragStart}
    alt="7"
    className="brand_logo2"
    onClick={handleClick}
  />,
  <Image
    src={partner9}
    webp={partner9webp}
    onDragStart={handleDragStart}
    alt="7"
    className="brand_logo2"
    onClick={handleClick}
  />,
];
const items3 = [
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/fiev_j3usdd.jpg',
    title: 'FIEV',
    desc: 'A Euro Symbiose é uma empresa de consultoria francesa, próxima das OEM’s francesas, especializada nas áreas de auditoria e formação nas áreas de sistemas de gestão da qualidade, meio ambiente e saúde / segurança, bem como na melhoria do desempenho operacional, particularmente presente nos mercados automóvel e aeroespacial. É responsável pelo desenvolvimento do referencial de auditoria de processo para a FIEV, Federação das Indústrias de Equipamentos para Veículos Francesa, referencial reconhecido pela Renault e PSA.',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/odette_qwxaax.jpg',
    title: 'ODETTE',
    desc: 'Odette International é uma organização, formada pela indústria automóvel e dedicada à indústria automóvel. Define os padrões para toda a cadeia de fornecimento, incluindo padrões para as comunicações electrónicas, incluindo protocolos EDI, troca de dados  e gestão da cadeia logística bem como gestão de toda a cadeia logística, inbound e outbound. Liga mais de 4.000 empresas através da cadeia logística da indústria automóvel.',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/plexus_ojgq1s.jpg',
    title: 'INTERACTION PLEXUS',
    desc: 'A Interaction Plexus, uma empresa do grupo Plexus International, é uma organização de formação, consultoria, auditoria, desenvolvimento da cadeia de fornecedores e tercerização de pessoal, que presta serviços altamente especializados em gestão de negócios, qualidade empresarial e certificação de sistemas de gestão.',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/PSA_qzdy9q.jpg',
    title: 'PSA',
    desc: 'A Euro Symbiose é uma empresa de consultoria francesa, próxima das OEM’s francesas, especializada nas áreas de auditoria e formação nas áreas de sistemas de gestão da qualidade, meio ambiente e saúde / segurança, bem como na melhoria do desempenho operacional, particularmente presente nos mercados automóvel e aeroespacial. A OPCO está agora qualificada para ministrar formação certificada APQP e PPAP de acordo com os novos requisitos PSA Peugeot e Citroën.',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230299/renault_t8yd6r.jpg',
    title: 'RENAULT NISSAN CONSULTING',
    desc: 'A Renault-Nissan Consulting é o consultor de referência da aliança Renault-Nissan Mitsubishi, mas também trabalha com clientes externos de qualquer setor, industrial e de serviços. O seu principal objetivo é  promover e facilitar a transformação dos processos produtivos, comerciais e organizacionais dos clientes através de consultoria e formação personalizado. São especialistas na aplicação de metodologias Lean, seja em ambientes produtivos (Lean Manufacturing), como nos demais processos da empresa (Lean Office).',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/smmt_mt9fv0.jpg',
    title: 'SMMT',
    desc: 'A Society of Motor Manufacturers and Traders ( SMMT ) é a associação comercial da indústria automobilística do Reino Unido. Tem como papel “promover os interesses da indústria automotiva do Reino Unido em casa e no exterior”.',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/vda_uogbqw.png',
    title: 'VDA QMC',
    desc: 'Centro de Gestão da Qualidade da Associação da Indústria Automóvel Alemã, criado para apoiar a indústria automóvel, tanto a nível de OEM’s como dos respectivos fornecedores, edita a série de normas VDA, onde se destaca a série VDA6.x sendo, igualmente, um dos oversight offices do IATF e responsável pela ISO/IATF16949. É detentora de uma rede de licenciados a nível global, entre os quais a OPCO em Portugal, entidade habilitada a ministrar formação original e reconhecida pela VDA QMC e pelas principais OEM’s Alemãs, bem como formação reconhecida a nível das principiais ferramentas da indústria automóvel (FMEA, SPC, MSA).',
  },
  {
    image:
      'https://res.cloudinary.com/nachsantos/image/upload/v1610230298/aiag_fowsob.jpg',
    title: 'AIAG',
    desc: 'Organização reconhecida a nível global, responsável pela publicação de referenciais tais como FMEA, SPC, MSA, APQP e PPAP, reconhecida a nível global e fundada pela Ford, GM e Chrysler em 1982 com vista ao desenvolvimento e promoção de soluções e referenciais para toda a indústria automóvel. A OPCO é associada do AIAG desde 2008, promovendo desde então as respectivas publicações e actividades.',
  },
];

const SliderPartners = () => {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="slider-partners">
      {items2.map((logo, i) => {
        return <div key={i}>{logo}</div>;
      })}
    </Slider>
  );
};
export default SliderPartners;

export const SliderClients = () => {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="slider-clients">
      {items.map((logo, i) => {
        return <div key={i}>{logo}</div>;
      })}
    </Slider>
  );
};

const Modal = (image) => {
  return Swal.fire({
    title: items3[image].title,
    text: items3[image].desc,
    imageWidth: 200,
    imageHeight: 200,
    imageAlt: 'Custom image',
    showClass: {
      popup: 'animate__animated animate__zoomIn',
    },
    hideClass: {
      popup: 'animate__animated animate__zoomOutDown',
    },
    width: 800,
    confirmButtonText: 'Close',
    confirmButtonColor: '#ff3f34',
  });
};
