import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../images/services/img1.jpg";
import img1webp from "../../images/services/img1.webp";
import img2 from "../../images/services/img2.jpg";
import img2webp from "../../images/services/img2.webp";
import img3 from "../../images/services/img3.jpg";
import img3webp from "../../images/services/img3.webp";
import "./Services2.css";
import ImgWithFallback from "../ImgFallback/index";
import { useHistory } from "react-router-dom";

const Services2 = () => {
  const { t } = useTranslation(["common"]);
  let history = useHistory();

  function handleClick(event) {
    event.preventDefault();
    history.push("/formacao");
  }

  return (
    <>
      <section className="section" id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t("common:services.title")}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t("common:services.subtitle")}{" "}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>

          <div class="row g-4 justify-content-center text-center">
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="card h-100">
                <ImgWithFallback
                  src={img1webp}
                  fallback={img1}
                  class="card-img-top"
                  alt="service1"
                  onClick={handleClick}
                  id="Formação"
                />
                <div class="card-body">
                  <h4 class="card-title">
                    {t("common:homepage.service1_title")}{" "}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="card h-100">
                <ImgWithFallback
                  src={img2webp}
                  fallback={img2}
                  class="card-img-top"
                  alt="service1"
                  onClick={handleClick}
                  id="Consultoria"
                />
                <div class="card-body">
                  <h4 class="card-title">
                    {t("common:homepage.service2_title")}{" "}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="card h-100">
                <ImgWithFallback
                  src={img3webp}
                  fallback={img3}
                  class="card-img-top"
                  alt="service1"
                  onClick={handleClick}
                  id="Auditoria"
                />{" "}
                <div class="card-body">
                  <h4 class="card-title">
                    {t("common:homepage.service3_title")}{" "}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services2;
