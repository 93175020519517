import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import LateralMenu from './components/LateralMenu/LateralMenu';
import { DataProvider } from './context/DataContext';
import { LanguageProvider } from './context/LanguageContext';
import './services/i18n';
import { store } from './store/store';

ReactDOM.render(
  <LanguageProvider>
    <DataProvider>
      <Provider store={store}>
        <LateralMenu />
        <App />
      </Provider>
    </DataProvider>
  </LanguageProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
