import React from "react";
import Home from "../components/Applications/home";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import NavbarApps from "../components/Applications/NavbarApps";
const Event = () => {
  return (
    <>
      <NavbarApps />
      <Home />
      <div className="container">
        <div className="row justify-content-center py-5">
          <div className="col-6 text-center">
            <ul class="list-group">
              <li class="list-group-item">
                <Link to="/aplicacoes/vda_63_qualification_as_process_auditor">
                  {" "}
                  VDA 6.3 – Qualification as Process Auditor (2016)
                </Link>
              </li>
              <li class="list-group-item">Em elaboração...</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Event;
