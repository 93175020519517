import React from 'react';
import Image from 'react-image-webp';
import { Link as LinkR } from 'react-router-dom';

import project from '../../files/project_v2.pdf';
import imagew from '../../images/projectbanner/imagew.webp';
import image from '../../images/projectbanner/projectbanner.png';
import './Projectbanner.css';

const ProjectBanner = ({ backgroundGrey }) => {
  return (
    <>
      <section className={backgroundGrey ? 'bg-gray' : 'home'} id="">
        <div className="text-center">
          <LinkR to={project} target="_blank">
            <Image
              src={image}
              webp={imagew}
              alt="project banner"
              className="img-fluid mx-auto d-block projectbanner rounded"
            />
          </LinkR>
        </div>
      </section>
    </>
  );
};

export default ProjectBanner;
