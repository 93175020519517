import React from "react";
import PrivacyComponent from "../components/PrivacyPolitic/PrivacyComponent_v2";
import Home from "../components/PrivacyPolitic/Home";

const PrivacyPolitics = () => {
  return (
    <>
      <Home />
      <PrivacyComponent />
    </>
  );
};

export default PrivacyPolitics;
