import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCounties = createAsyncThunk(
  "countiesSlice/getCounties",
  async () => {
    const response = await axios.get("https://json.geoapi.pt/municipios");
    return response;
  }
);

const initialState = {
  status: null,
  data: [],
};

const districtsSlice = createSlice({
  name: "districtsSlice",
  initialState,
  extraReducers: {
    [getCounties.pending]: (state) => {
      state.status = "Pending";
    },

    [getCounties.fulfilled]: (state, action) => {
      state.status = "Fulfilled";
      state.data = action.payload.data;
    },

    [getCounties.rejected]: (state) => {
      state.status = "Rejected";
    },
  },
});

export default districtsSlice.reducer;
