import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getParishs = createAsyncThunk(
  "parishSlice/getParishs",
  async () => {
    const response = await axios.get("https://json.geoapi.pt/freguesias");
    return response;
  }
);

const initialState = {
  status: null,
  data: [],
};

const parishSlice = createSlice({
  name: "parishSlice",
  initialState,
  extraReducers: {
    [getParishs.pending]: (state) => {
      state.status = "Pending";
    },

    [getParishs.fulfilled]: (state, action) => {
      state.status = "Fulfilled";
      state.data = action.payload.data;
    },

    [getParishs.rejected]: (state) => {
      state.status = "Rejected";
    },
  },
});

export default parishSlice.reducer;
