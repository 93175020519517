import React from "react";
import Footer from "../Footer";
import Home from "../Applications/home";
import Forms from "../Applications/forms";
import NavbarApps from "./NavbarApps";

const Application = () => {
  return (
    <>
      <NavbarApps />
      <Home />
      <Forms />
      <Footer />
    </>
  );
};

export default Application;
