import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Client from '../Contentfull';

export const getEvents = createAsyncThunk('events/getEvents', async () => {
  return await Client.getEntries({
    content_type: 'event',
  }).then((response) => formatData(response));
});

function formatData(response) {
  let items = response.items;
  let today = new Date();
  let tempSliderArray = [];
  let events = [];
  items.map((item) => {
    let tempObj = {
      title: '',
      start: '',
      end: '',
      allDay: '',
      url: '',
      info: { duration: '', schedule: '', image: '', urlParameter: '' },
      urlParameter: '',
    };
    tempObj.title = item.fields.title;
    tempObj.start = new Date(item.fields.start);
    tempObj.end = new Date(item.fields.end);
    tempObj.allDay = item.fields.allDay;
    tempObj.url = item.fields.url;
    tempObj.info.image = item.fields.image.fields.file.url;
    tempObj.info.duration = item.fields.duration;
    tempObj.info.schedule = item.fields.schedule;
    tempObj.info.urlParameter = item.fields.urlParameter;
    events.push(tempObj);
    if (tempObj.start > today) {
      tempSliderArray.push(tempObj);
    }
    return null;
  });
  let temp = { events, tempSliderArray };
  return temp;
}

const initialState = {
  status: null,
  events: [],
  eventSlider: [],
};

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.status = 'Pending';
    },
    [getEvents.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.events = action.payload.events;
      state.eventSlider = action.payload.tempSliderArray;
    },
    [getEvents.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default eventsSlice.reducer;
