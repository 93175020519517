import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CartItem from '../../components/CartItems/CartItem';
import { Context } from '../../context/DataContext';
import './Cart.css';

const Cart = () => {
  const { t } = useTranslation(['common']);
  const { items, subtotal, calculateSubtotal } = useContext(Context);
  const history = useHistory();
  function handleResume() {
    history.push({
      pathname: `/carro`,
    });
  }
  useEffect(() => {
    calculateSubtotal();
  }, [items, subtotal, calculateSubtotal]);

  let cartItems = null;
  function itemsCount() {
    let count = 0;

    items.forEach((item) => {
      count = count + item.quantity;
    });

    if (count === 0) {
      return t('common:shopPage.no-items-added');
    } else if (count > 1) {
      return count + ' items no total';
    } else {
      return count + ' items';
    }
  }

  if (items.length > 0) {
    cartItems = items.map((item) => <CartItem key={item.id} item={item} />);
  } else {
    cartItems = (
      <div className="row text-center pt-3">
        <h5 classname="">{t('common:shopPage.products-table.no-products')}</h5>
      </div>
    );
  }
  function payButton() {
    let count = 0;
    items.forEach((item) => {
      count = count + item.quantity;
    });

    if (count > 0) {
      return (
        <button className="btn btn-primary" onClick={(event) => handleResume()}>
          {t('common:shopPage.pay-now')}
        </button>
      );
    }
  }
  function showTotal() {
    let count = 0;
    items.forEach((item) => {
      count = count + item.quantity;
    });

    if (count > 0) {
      return (
        <>
          <h4 className="subtotalAmount">€{subtotal}</h4>
          <h5>
            <strong>{t('common:shopPage.subtotal')}</strong>
          </h5>
        </>
      );
    }
  }

  return (
    <>
      <div className="row text-center">
        <h3>{t('common:shopPage.car-page-title')}</h3>
      </div>
      <div className="row text-center border-bottom">
        <h5 className="">{itemsCount()}</h5>
      </div>
      <div className="row">{cartItems}</div>
      <div className="row text-center">
        <strong>{showTotal()}</strong>
      </div>
      <div className="row text-center pt-2">
        <div className="col-12">{payButton()}</div>
      </div>
    </>
  );
};

export default Cart;
