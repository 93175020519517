import React from 'react';

import { Aside } from './aside';
import CenterContainerCourses from './centerContainerCourses';
import './courses.css';
import LeftContainerCourses from './leftContainerCourses';

const Catalog = ({ url }) => {
  return (
    <>
      <section id="catalog">
        <div className="container-fluid home-courses">
          <div className="row justify-content-md-center">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-3">
              <LeftContainerCourses />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-2 text-center">
              <CenterContainerCourses />
            </div>
            <aside className="col-xl-2 col-lg-2 col-md-12 col-sm-12">
              <Aside />
            </aside>
          </div>
        </div>
      </section>
    </>
  );
};

export default Catalog;
