import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./locals/en/common.json";
import common_pt from "./locals/pt/common.json";
import common_es from "./locals/es/common.json";
import common_de from "./locals/de/common.json";
import common_fr from "./locals/fr/common.json";

// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
	en: {
		common: common_en,
	},
	pt: {
		common: common_pt,
	},
	es: {
		common: common_es,
	},
	de: {
		common: common_de,
	},
	fr: {
		common: common_fr,
	},
};

i18next.use(initReactI18next).init({
	resources,
	lng: "pt",
	interpolation: {
		escapeValue: false,
	},
});

export default i18next;
