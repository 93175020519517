import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Client from '../Contentfull';

export const getNews = createAsyncThunk('newsSlice/getNews', async () => {
  return await Client.getEntries({
    content_type: 'news',
    order: 'sys.createdAt',
  }).then((res) => formatData(res));
});

function formatData(res) {
  let items = res.items;
  let arr = [];
  items.forEach((item) => {
    let tempObj = {
      title: '',
      image: '',
      body: {},
      language: '',
      id: '',
      createdAt: '',
      updatedAt: '',
    };
    tempObj.title = item.fields.title;
    tempObj.language = item.fields.language;
    tempObj.image = item.fields.image.fields.file.url;
    tempObj.body = item.fields.content;
    tempObj.id = item.sys.id;
    tempObj.createdAt = item.sys.createdAt;
    tempObj.updatedAt = item.sys.updatedAt;
    arr = [...arr, tempObj];
  });
  return arr.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
}

const initialState = {
  status: null,
  data: [],
};

const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  extraReducers: {
    [getNews.pending]: (state) => {
      state.status = 'Pending';
    },

    [getNews.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload;
    },

    [getNews.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default newsSlice.reducer;
