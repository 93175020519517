import React from 'react';

const Home = ({ title }) => {
  return (
    <>
      <section className="home">
        {/* <div className="bg-overlay"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-wrapper home-wrapper-sm text-center">
                <h2 className="text-colored">{title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
