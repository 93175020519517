import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import team1 from '../../images/team/1.jpg';
import team1webp from '../../images/team/1.webp';
import team2 from '../../images/team/2.jpg';
import team2webp from '../../images/team/2.webp';
import team4 from '../../images/team/4.jpg';
import team4webp from '../../images/team/4.webp';
import team6 from '../../images/team/6.jpg';
import team6webp from '../../images/team/6.webp';
import team7 from '../../images/team/7.jpg';
import team7webp from '../../images/team/7.webp';
import team9 from '../../images/team/9.png';
import team9webp from '../../images/team/9.webp';
import team10 from '../../images/team/10.png';
import team10webp from '../../images/team/10.webp';
import team11 from '../../images/team/11.png';
import team11webp from '../../images/team/11.webp';
import ImgWithFallback from '../ImgFallback/index';
import './Team.css';

const Team = () => {
  const { t } = useTranslation(['common']);
  const [show, setShow] = useState(false);
  const [title, settitle] = useState('');
  const [text, settext] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    let title = e.target.name;
    let text = t(`common:team.curriculum.team${e.target.alt}`);
    settitle(title);
    settext(text);
    setShow(true);
  };

  return (
    <>
      {/* <section className="section bg-gray home" id="team"> */}
      <section className="section" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t('common:team.title')}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:team.subtitle')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row team text-center">
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".1s"
                  >
                    <ImgWithFallback
                      src={team1webp}
                      fallback={team1}
                      alt="1"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Pedro Silva"
                    />
                    <h4>Pedro Silva</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team1')}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".3s"
                  >
                    <ImgWithFallback
                      src={team2webp}
                      fallback={team2}
                      alt="2"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Teresa Martins"
                    />
                    <h4>Teresa Martins</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team2')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team4webp}
                      fallback={team4}
                      alt="4"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Cláudia Pomares"
                    />
                    <h4>Cláudia Pomares</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team4')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team6webp}
                      fallback={team6}
                      alt="6"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Vânia Azevedo"
                    />
                    <h4>Vânia Azevedo</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team6')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team7webp}
                      fallback={team7}
                      alt="7"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Rui Aguiar"
                    />
                    <h4>Rui Aguiar</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team7')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team9webp}
                      fallback={team9}
                      alt="9"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Irina Guerreiro"
                    />
                    <h4>Irina Guerreiro</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team9')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team10webp}
                      fallback={team10}
                      alt="10"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Carla Galvão"
                    />
                    <h4>Carla Galvão</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team10')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="team-member animated fadeInDown wow"
                    data-wow-delay=".5s"
                  >
                    <ImgWithFallback
                      src={team11webp}
                      fallback={team11}
                      alt="11"
                      className="img-fluid rounded-circle img-team"
                      onClick={handleShow}
                      name="Marcelo Claúdio"
                    />
                    <h4>Marcelo Claúdio</h4>
                    <p className="text-muted">
                      {t('common:team.functions.team11')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="text-center">
                <Modal.Title>
                  <h3>{title}</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="">{text}</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="text-center"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
