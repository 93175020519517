import React from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import './courses.css';

const CoursesTable = ({ data }) => {
  let history = useHistory();

  const columns = [
    {
      name: 'Designação',
      selector: 'title',
      sortable: true,
      maxWidth: '400px',
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ fontWeight: 500 }}>
          {row.title}
        </div>
      ),
    },
    {
      name: 'Presencial',
      selector: 'presential',
      maxWidth: '50px',
      center: true,
    },
    {
      name: 'Online',
      selector: 'online',
      maxWidth: '50px',
      center: true,
    },
    {
      name: 'Partner',
      grow: 0,
      cell: (row) => (
        <img
          data-tag="allowRowEvents"
          height="50px"
          width="50px"
          alt="thumbnail1"
          src={row.thumbnail}
        />
      ),
    },
  ];

  const handleRowClick = (row) => {
    let url = `formacao/${row.url}`;
    history.push(url);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        striped
        highlightOnHover
        pagination
        pointerOnHover
        className="titles-table"
        data-tag="allowRowEvents"
        onRowClicked={handleRowClick}
      />
    </>
  );
};

export default CoursesTable;
