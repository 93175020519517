import React from 'react';
import { useTranslation } from 'react-i18next';

const HomeCourses = ({ url }) => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <section className="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-wrapper home-wrapper-sm text-center">
                <h2 className="animated fadeInDown wow" data-wow-delay=".1s">
                  {url === '/formacao' || '/formacao2'
                    ? t('common:courses-page.title1')
                    : url === '/consultoria'
                    ? t('common:catalogs-page.consulting-title')
                    : url === '/auditorias&projetos'
                    ? t('common:catalogs-page.audit-title')
                    : ''}

                  <span className="text-colored">
                    {' '}
                    {url === '/formacao' || '/formacao2'
                      ? t('common:courses-page.title2')
                      : url === '/consultoria'
                      ? t('common:catalogs-page.consulting-subtitle')
                      : url === '/auditorias&projetos'
                      ? t('common:catalogs-page.audit-subtitle')
                      : ''}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCourses;
