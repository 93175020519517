import React, { useContext } from 'react';
import { RiDeleteBin3Fill } from 'react-icons/ri';

import { Context } from '../../context/DataContext';
import './CartItem.css';

const CartItem = (props) => {
  const { removeFromCart } = useContext(Context);

  return (
    <>
      <div className="row py-2">
        <div className="col-2">
          <img
            className="img-fluid"
            src={props.item.image}
            alt={props.item.name}
          />
        </div>
        <div className="col-10">
          <h5>
            {props.item.name} <strong>{props.item.quantity} x </strong>
            <strong>{props.item.price}€</strong>{' '}
            <span role="button" className="p-1">
              <RiDeleteBin3Fill
                onClick={() => removeFromCart(props.item.id)}
                size={20}
                color="red"
              />
            </span>
          </h5>
        </div>
      </div>
    </>
  );
};

export default CartItem;
