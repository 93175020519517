import React from "react";
import { useTranslation } from "react-i18next";
import SliderPartners, { SliderClients } from "../BrandsGallery/index2";
import "./Testimonials.css";
const TestimonialsBrands = () => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <section class="section" id="testimonials">
        <div class=""></div>
        <div class="container clients section">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="title-box">
                <h2 class=" text-white">{t("common:testimonials.title")}</h2>
                <h3 class="fadeIn animated wow text-white" data-wow-delay=".1s">
                  {t("common:testimonials.subtitle")}
                </h3>
                <div class="border"></div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="">
                {/* <Gallery /> */}
                {/* <Gallery2 /> */}
                <SliderPartners />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-gray home" id="team">
        <div class="container clients section border rounded">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <div class="title-box">
                <h2 class=" text-white">
                  {t("common:about-page.clients_title")}
                </h2>
                <h3 class="fadeIn animated wow text-white" data-wow-delay=".1s">
                  {t("common:about-page.clients_text")}
                </h3>
                <div class="border"></div>
              </div>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-lg-12">
              <div class="">
                <SliderClients />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialsBrands;
