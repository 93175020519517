import { RiCheckboxCircleLine } from 'react-icons/ri';

import opco from '../../images/logo2.png';

const yes = <RiCheckboxCircleLine id="true" size="20px" color="green" />;

const data_en = () => {
  return [
    {
      id: 2,
      title: 'VSM - VALUE CHAIN ​​ANALYSIS',
      category1: 'Training',
      category2: 'Automotive',
      category3: 'Lean / Productivity',
      opco: true,
      thumbnail: opco,
      partner: 'OPCO',
      description:
        'VSM (Value Stream Mapping) is a very useful method and has been one of the most used in the universe of lean thinking applications in industrial and service companies.',
      objectives:
        "Provide trainees with the necessary skills to characterize the value chain and plan improvement actions in order to satisfy customers' needs, from relations with suppliers and production and sales cycles to the stage of distribution to the final consumer. Each link in this chain of activities is interconnected. ",
      online: yes,
      presential: yes,
      online_hours: '8',
      presential_hours: '24',
      associated_training: [],
    },
    {
      id: 3,
      title: 'EQUIPMENT EFFECTIVENESS - OEE',
      category1: 'Training',
      category2: 'Automotive',
      category3: 'Lean / Productivity',
      opco: true,
      thumbnail: opco,
      partner: 'OPCO',
      description:
        'Most manufacturers are unable to identify the main losses that contribute to the low productivity of the machines. Through the OEE indicator it is possible to determine the true operational efficiency, improving the availability and reliability of the equipment and acting on the causes of the losses.',
      objectives:
        "Provide trainees with the necessary knowledge to implement the metrics and concepts applied to OEE equipment, as well as the skills to monitor and improve the efficiency of manufacturing processes, as well as optimize the company's maintenance and production processes.",
      online: yes,
      presential: yes,
      online_hours: '8',
      presential_hours: '24',
      associated_training: [],
    },
    {
      id: 4,
      title: 'STANDARDIZATION OF WORK',
      category1: 'Training',
      category2: 'Automotive',
      category3: 'Lean / Productivity',
      opco: true,
      thumbnail: opco,
      partner: 'OPCO',
      description:
        'Standardization is the process of developing and implementing technical standards. It aims to define technical specifications that help to maximize the compatibility, productivity, safety or quality of a given process, product or service.',
      objectives:
        'Provide trainees with the necessary knowledge to implement the metrics and concepts applied to the standardization of work, as well as skills to identify the advantages of these methods and tools.',
      online: yes,
      presential: yes,
      online_hours: '8',
      presential_hours: '8',
      associated_training: [],
    },
    {
      id: 5,
      title: 'JUST IN TIME_KANBAN - SEQUENCING',
      category1: 'Training',
      category2: 'Automotive',
      category3: 'Lean / Productivity',
      opco: true,
      thumbnail: opco,
      partner: 'OPCO',
      description:
        'Extremely precise shopping, production and inventory control methodology based on cards that allow visual control of the inventory position and production planning of any item, at any time.',
      objectives:
        'Provide trainees with skills in the areas of JIT, Kanban and sequencing, as well as the necessary knowledge for the operationalization of logistical methods that aim to minimize inventories.',
      online: yes,
      presential: yes,
      online_hours: '8',
      presential_hours: '24',
      associated_training: [],
    },
    {
      id: 6,
      title: 'KAIZEN - CONTINUOUS IMPROVEMENT',
      category1: 'Training',
      category2: 'Automotive',
      category3: 'Lean / Productivity',
      opco: true,
      thumbnail: opco,
      partner: 'OPCO',
      description:
        'The Kaizen methodology is based on the sociocultural principles of the East, which requires a commitment from all employees of the company in the sense of continuous improvement, based on a form of management aimed at maximizing productivity and profitability, and consequent cost reduction.',
      objectives:
        "It aims to provide trainees with the necessary knowledge and practices in order to increase productivity by simplifying processes; understanding the Kaizen philosophy of Continuous Improvement based on the principles of cause-effect relationships, problem solving and enhancing the individual's development in the company as a source of innovation and improvement. ",
      online: yes,
      presential: yes,
      online_hours: '16',
      presential_hours: '16',
      associated_training: [],
    },
  ];
};

export default data_en;
