import React from "react";
import { useTranslation } from "react-i18next";
import "./MissionElement.css";

const Mission = () => {
	const { t } = useTranslation(["common"]);
	return (
		<>
			<section className="home bg-img-3" id="values">
				<div className="bg-overlay "></div>
				<div className="container">
					<div className="row row-title">
						<div className="col-lg-12 text-center">
							<div className="title-box">
								<h2 className="title-alt">
									{t("common:about-page.navbar.item2")}{" "}
								</h2>
								<h3
									className="fadeIn animated wow h3-color"
									data-wow-delay=".1s"
								>
									{t("common:about-page.mission_phrase")}
								</h3>
								<div className="border"></div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="home-wrapper text-center home-wrapper2">
								<h2
									className="animated fadeInDown wow text-white"
									data-wow-delay=".1s"
								>
									<span className="text-colored">
										{t("common:about-page.vision_title")}
									</span>
								</h2>
								<h4
									className="animated fadeInDown wow text-light line-height-1_6 "
									data-wow-delay=".2s"
								>
									{t("common:about-page.vision_text")}
								</h4>
								<div className="clearfix"></div>
							</div>
							<div className="home-wrapper text-center home-wrapper2">
								<h2
									className="animated fadeInDown wow text-white"
									data-wow-delay=".1s"
								>
									<span className="text-colored">
										{t("common:about-page.mission_title")}
									</span>
								</h2>
								<h4
									className="animated fadeInDown wow text-light line-height-1_6 "
									data-wow-delay=".2s"
								>
									{t("common:about-page.mission_text")}
								</h4>
								<div className="clearfix"></div>
							</div>
							<div className="home-wrapper text-center home-wrapper2">
								<h2
									className="animated fadeInDown wow text-white"
									data-wow-delay=".1s"
								>
									<span className="text-colored">
										{t("common:about-page.politic_title")}
									</span>
								</h2>
								<h4
									className="animated fadeInDown wow text-light line-height-1_6 "
									data-wow-delay=".2s"
								>
									{t("common:about-page.politic_text")}
								</h4>
								<div className="clearfix"></div>
							</div>
							<div className="home-wrapper text-center home-wrapper2">
								<h2
									className="animated fadeInDown wow text-white"
									data-wow-delay=".1s"
								>
									<span className="text-colored">
										{t("common:about-page.responsability_title")}
									</span>
								</h2>
								<h4
									className="animated fadeInDown wow text-light line-height-1_6 "
									data-wow-delay=".2s"
								>
									{t("common:about-page.responsability_text")}
								</h4>
								<div className="clearfix"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Mission;
