import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
  filterSearchInput,
  filterSelect2,
} from '../../redux/formationsCatalogSlicev2';

const LeftContainerCourses = () => {
  const { t } = useTranslation(['common']);
  const [searchBarString, setSearchBarString] = useState('');

  const dispatch = useDispatch();
  const buttonId = useSelector((state) => state.formationCatalog2.buttonId);
  const catalogData = useSelector(
    (state) => state.formationCatalog2.fileredData,
  );

  const searchItems = (e) => {
    e.preventDefault();
    console.log(searchBarString);
    dispatch(filterSearchInput(searchBarString));
  };
  const handleChangeSelect2 = (selectedOption) => {
    console.log(selectedOption);
    dispatch(filterSelect2(selectedOption.value));
  };

  const checkDisabled = () => {
    return buttonId > 0 ? true : false;
  };

  const searchBarText = `🔍 ${t('common:courses-page.search-bar')}`;
  const foundItemsText = `${t('common:courses-page.find-courses')} ${
    catalogData.length
  }
  items`;

  const optionsSelect2 = [
    {
      value: 'LEAN/PRODUTIVIDADE',
      label: `${t('common:select-trainning.select1')}`,
    },
    {
      value: 'ENGENHARIA DA QUALIDADE',
      label: `${t('common:select-trainning.select2')}`,
    },
    {
      value: 'GESTÃO E OPERAÇÕES',
      label: `${t('common:select-trainning.select3')}`,
    },
    { value: 'SOFT SKILLS', label: `${t('common:select-trainning.select4')}` },
    {
      value: 'RECURSOS HUMANOS',
      label: `${t('common:select-trainning.select5')}`,
    },
    {
      value:
        'AMBIENTE, SAUDE E SEGURANÇA NO TRABALHO, ECONOMIA CIRCULAR E SUSTENTABILIDADE',
      label: `${t('common:select-trainning.select6')}`,
    },
    {
      value: 'WORKSHOPS PRÁTICOS',
      label: `${t('common:select-trainning.select7')}`,
    },
    {
      value: 'FORMAÇÃO À MEDIDA',
      label: `${t('common:select-trainning.select8')}`,
    },
    {
      value: 'AUDITORIAS INTERNAS',
      label: `${t('common:select-trainning.select9')}`,
    },
    {
      value: 'PROJETOS DE CERTIFICAÇÃO',
      label: `${t('common:select-trainning.select10')}`,
    },
  ];

  return (
    <>
      <div className="row">
        {' '}
        <form onSubmit={(e) => searchItems(e)}>
          <div className="row">
            <div className="col-8">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="search-text"
                  aria-describedby="searchText"
                  placeholder={searchBarText}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSearchBarString(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-2">
              <button type="submit" class="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <span class="border bg-light rounded p-3 m-0">
          <h4 className="text-center">
            {t('common:courses-page.find-formation')}:
          </h4>
          <Select
            className="pt-2"
            options={optionsSelect2}
            placeholder={t('common:courses-page.actuation-area')}
            onChange={handleChangeSelect2}
            isDisabled={checkDisabled()}
          />
          <h5 className="text-center pt-2">{foundItemsText}</h5>
        </span>
      </div>
    </>
  );
};

export default LeftContainerCourses;
