import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const base_URL = process.env.REACT_APP_API_URL;

export const sendContactForm = createAsyncThunk(
  "contactSlide/sendContactForm",
  async (emailData) => {
    const response = await axios.post(
      `${base_URL}/email/newcontact`,
      emailData
    );
    return response.status;
  }
);

const initialState = {
  status: null,
  sendFormStatus: null,
};

const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  extraReducers: {
    [sendContactForm.pending]: (state) => {
      state.status = "Pending";
    },
    [sendContactForm.fulfilled]: (state, action) => {
      state.status = "Fulfilled";
      state.sendFormStatus = action.payload;
    },
    [sendContactForm.rejected]: (state) => {
      state.status = "Rejected";
    },
  },
});

export default contactSlice.reducer;
