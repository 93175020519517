import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyComponent = () => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <div className="container p-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <p className="text-center">
              <b>{t('common:privacy-page.header1')}</b>
            </p>
            <p>
              <p>{t('common:privacy-page.header2')}</p>
              <>{t('common:privacy-page.header3')}</>
            </p>
            <p>
              <p>
                <b>1. {t('common:privacy-page.item1-title')}</b>
              </p>
              {t('common:privacy-page.item1-text')}
            </p>
            <p>
              <p>
                <b>2. {t('common:privacy-page.item2-title')}</b>
              </p>
              {t('common:privacy-page.item2-text')}
            </p>
            <p>
              <p>
                <b>3. {t('common:privacy-page.item3-title')}</b>
              </p>
              {t('common:privacy-page.item3-text.item3-subitem1')}
              <ul>
                <li>{t('common:privacy-page.item3-text.item3-subitem2')}</li>
                <li>{t('common:privacy-page.item3-text.item3-subitem3')}</li>
                <li>{t('common:privacy-page.item3-text.item3-subitem4')}</li>
                <li>{t('common:privacy-page.item3-text.item3-subitem5')}</li>
                <li>{t('common:privacy-page.item3-text.item3-subitem6')}</li>
                <li>{t('common:privacy-page.item3-text.item3-subitem8')}</li>
              </ul>
            </p>
            <p>
              <p>
                <b>4. {t('common:privacy-page.item4-title')}</b>
              </p>
              <p>{t('common:privacy-page.item4-text.item4-subitem1')}</p>
              <p>{t('common:privacy-page.item4-text.item4-subitem2')}</p>
            </p>
            <p>
              <p>
                <b>5. {t('common:privacy-page.item5-title')}</b>
              </p>
              <ul>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem1')}
                  {/* Os seus dados de identificação (por exemplo, o seu nome,
                  imagem, apelido, idioma e país a partir do qual interage
                  connosco, dados de contacto etc.); */}
                </li>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem2')}
                  {/* Informações económicas e de transações (por exemplo, os seus
                  dados de pagamento ou do cartão, informações relativas às suas
                  compras, encomendas, devoluções, etc.); */}
                </li>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem3')}
                  {/* Dados de ligação, geolocalização e/ou navegação (por exemplo,
                  os dados de localização, o número de identificação do
                  dispositivo ou ID publicitário, etc.); */}
                </li>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem4')}
                  {/* Informações comerciais (por exemplo, caso tenha subscrito a
                  nossa newsletter); */}
                </li>
              </ul>
              <p>
                {t('common:privacy-page.item5-text.item5-subitem5')}
                {/* Quando solicitarmos que preencha os seus dados pessoais para lhe
                dar acesso a qualquer funcionalidade ou serviço da Plataforma,
                iremos assinalar determinados campos como obrigatórios, uma vez
                que estas são as informações que precisamos para lhe podermos
                fornecer o serviço ou dar acesso à funcionalidade em questão. Há
                que ter em atenção que, se decidir não disponibilizar os
                referidos dados, pode não conseguir completar o seu registo de
                utilizador ou pode não conseguir desfrutar desses serviços ou
                funcionalidades. */}
              </p>
              <p>
                {t('common:privacy-page.item5-text.item5-subitem6')}
                {/* Os Dados do Titular são recolhidos e tratados pela
                <b>RH OPCO - ACADEMIA, L.DA</b>, nas seguintes situações
                principais: */}
              </p>
              <ul>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem7')}
                  {/* Para a execução de um contrato no qual o Titular é parte, ou
                  para diligências pré-contratuais a pedido do Titular; */}
                </li>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem8')}
                  {/* Para efeito dos interesses legítimos prosseguidos pela
                  <b>RH OPCO - ACADEMIA, L.DA</b>, ou por terceiros no âmbito da
                  relação relevante estabelecida com o Cliente, sem prejuízo dos
                  direitos e liberdades fundamentais do Titular e do direito de
                  oposição previsto na cláusula 12 */}
                </li>
              </ul>
              <p>
                {t('common:privacy-page.item5-text.item5-subitem9')}
                {/* Os Dados do Titular podem ainda ser recolhidos e tratados pela
                <b>RH OPCO - ACADEMIA, L.DA</b>, se: */}
              </p>
              <ul>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem10')}O
                  {/* tratamento for necessário para o cumprimento de uma obrigação
                  jurídica a que a <b>RH OPCO - ACADEMIA, L.DA</b>, esteja
                  sujeita; */}
                </li>
                <li>
                  {t('common:privacy-page.item5-text.item5-subitem11')}O
                  {/* tratamento for necessário para a defesa de interesses vitais
                  do Titular ou de outra pessoa singular. */}
                </li>
              </ul>
              <p>
                {t('common:privacy-page.item5-text.item5-subitem12')}
                {/* Caso não se verifique nenhuma das situações acima indicadas, os
                Dados do Titular só podem ser recolhidos e tratados se o Titular
                tiver dado o seu consentimento explícito para o seu tratamento
                para uma ou mais finalidades específicas. */}
              </p>
            </p>
            <p>
              <p>
                <b>6. {t('common:privacy-page.item6-title')}</b>
              </p>
              <p>
                {t('common:privacy-page.item6-text.item6-subitem1')}
                {/* Em termos gerais, a <b>OPCO - ACADEMIA, L.DA</b>, utiliza os
                Dados do Titular para as seguintes finalidades: */}
              </p>
              <ul>
                <li> {t('common:privacy-page.item6-text.item6-subitem2')}</li>
                <ul>
                  <li>{t('common:privacy-page.item6-text.item6-subitem3')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem4')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem5')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem6')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem7')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem8')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem9')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem10')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem11')}</li>
                  <li>{t('common:privacy-page.item6-text.item6-subitem12')}</li>
                </ul>
              </ul>
            </p>
            <p>
              <p>
                <b>7.{t('common:privacy-page.item7-title')}</b>
              </p>
              <p>
                <p>{t('common:privacy-page.item7-text.item7-subitem1')}</p>
                <p>{t('common:privacy-page.item7-text.item7-subitem2')}</p>
                <p>{t('common:privacy-page.item7-text.item7-subitem3')}</p>
                <p>{t('common:privacy-page.item7-text.item7-subitem4')}</p>
              </p>
            </p>
            <p>
              <p>
                <b>8. {t('common:privacy-page.item8-title')}</b>
              </p>
              <p>{t('common:privacy-page.item8-text.item8-subitem1')}</p>
              <p>{t('common:privacy-page.item8-text.item8-subitem2')}</p>
              <p>{t('common:privacy-page.item8-text.item8-subitem3')}</p>
              <p>{t('common:privacy-page.item8-text.item8-subitem4')}</p>
              <p>{t('common:privacy-page.item8-text.item8-subitem5')}</p>
            </p>
            <p>
              <p>
                <b>9. {t('common:privacy-page.item9-title')}</b>
              </p>
              <p>{t('common:privacy-page.item9-text.item9-subitem1')}</p>
              <>{t('common:privacy-page.item9-text.item9-subitem2')}</>
            </p>
            <p>
              <p>
                <b>10. {t('common:privacy-page.item10-title')}</b>
              </p>
              <p>{t('common:privacy-page.item10-text.item10-subitem1')}</p>
              <p>{t('common:privacy-page.item10-text.item10-subitem2')}</p>
              <p>{t('common:privacy-page.item10-text.item10-subitem3')}</p>
              <p>{t('common:privacy-page.item10-text.item10-subitem4')}</p>
              <p>{t('common:privacy-page.item10-text.item10-subitem5')}</p>
              <ul>
                <li>{t('common:privacy-page.item10-text.item10-subitem6')}</li>
                <li>{t('common:privacy-page.item10-text.item10-subitem7')}</li>
                <li>{t('common:privacy-page.item10-text.item10-subitem8')}</li>
                <li>{t('common:privacy-page.item10-text.item10-subitem9')}</li>
              </ul>
            </p>
            <p>
              <p>
                <b>11. {t('common:privacy-page.item11-title')}</b>
              </p>
              <p>{t('common:privacy-page.item11-text')}</p>
            </p>
            <p>
              <p>
                <b> 12. {t('common:privacy-page.item12-title')}</b>
              </p>
              <p>
                {t('common:privacy-page.item12-text.item12-subitem1')}
                {/* Cabe ao titular de dados pessoais um conjunto de direitos,
                consagrados desde logo no RGPD, dos quais se destacam: */}
              </p>
              <p>
                <ul>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem2')}
                    {/* Direito à transparência (artigo 12.º) */}
                    <ul>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem3')}
                        {/* O responsável pelo tratamento toma as medidas adequadas
                        para fornecer ao titular as informações a que se referem
                        os artigos 13.º e 14.º e qualquer comunicação prevista
                        nos artigos 15.º a 22.º e 34.º a respeito do tratamento,
                        de forma concisa, transparente, inteligível e de fácil
                        acesso, utilizando uma linguagem clara e simples. */}
                      </li>
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem4')}
                    {/* Direito à informação (artigos 13.º e 14.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem5')}
                      {/* Independemente dos dados pessoais terem sido recolhidos
                      junto do respetivo titular ou não, tem o responsável pelo
                      tratamento que prestar àquele informações. Através da
                      presente Política de Privacidade a
                      <b>RH OPCO - ACADEMIA, L.DA</b>, presta ao Titular as
                      seguintes informações acerca da finalidade e do tratamento
                      que será realizado relativamente aos dados pessoais: */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem6')}
                        {/* Os contactos da <b>RH OPCO - ACADEMIA, L.DA</b>, são os
                        indicados neste website; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem7')}
                        {/* O fundamento jurídico e as finalidades do tratamento a
                        que os dados pessoais se destinam são os mencionados nas
                        cláusulas 3 e 6 supra; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem8')}
                        {/* São destinatários dos Dados Pessoais os mencionados na
                        cláusula 3; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem9')}
                        {/* Os Dados Pessoais serão armazenados e conservados apenas
                        pelo período mínimo necessário para as finalidades que
                        motivaram a sua recolha ou o seu posterior tratamento,
                        tendo como limite máximo o prazo ordinário da prescrição
                        ordinária previsto na lei civil, findo o qual os mesmos
                        serão eliminados; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem10')}
                        {/* O Titular tem o direito de solicitar à
                        <b>RH OPCO - ACADEMIA, L.DA</b>, o acesso aos Dados
                        Pessoais e a respetiva retificação, bem como o direito
                        de se opor ao seu tratamento ou de solicitar a
                        portabilidade, o apagamento ou limitação dos mesmos, nos
                        casos e termos previstos nas cláusulas 8 e seguintes da
                        presente Política de Privacidade; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem11')}
                        {/* Nos casos em que o tratamento dos dados recolhidos se
                        basear no consentimento do Titular, este tem o direito
                        de retirar o consentimento em qualquer altura, sem
                        comprometer a licítude do tratamento efetuado com base
                        no consentimento previamente dado; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem12')}
                        {/* O Titular tem o direito de apresentar reclamação junto
                        da Comissão Nacional de Proteção de Dados (CNPD); */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem13')}
                        {/* No caso de os Dados do Titular não serem recolhidos
                        diretamente pela<b>RH OPCO - ACADEMIA, L.DA</b>, junto
                        do Titular, além das informações referidas acima, este
                        será informado acerca das categorias de dados pessoais
                        objeto de tratamento e, bem assim, acerca da origem dos
                        dados e, eventualmente, se provêm de fontes acessíveis
                        ao público; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem14')}
                        {/* Caso a <b>RH OPCO - ACADEMIA, L.DA</b>, pretenda
                        proceder ao tratamento posterior dos Dados do Titular
                        para um fim que não seja aquele para o qual os dados
                        foram recolhidos, antes desse tratamento a
                        <b>RH OPCO - ACADEMIA, L.DA</b>, fornecerá ao Titular
                        informações sobre esse fim e quaisquer outras
                        informações pertinentes, nos termos acima referidos. */}
                      </li>
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem15')}
                    {/* Direito de acesso (artigo 15.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem16')}
                      {/* O titular dos dados tem, nomeadamente, o direito de: obter
                      do responsável pelo tratamento a confirmação de que os
                      dados pessoais que lhe dizem respeito são ou não objeto de
                      tratamento; aceder aos seus dados pessoais e a algumas
                      informações; solicitar cópia dos dados pessoais sujeitos a
                      tratamento. */}
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem17')}
                    {/* Direito de retificação do dados pessoais (artigo 16.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem18')}
                      {/* O titular tem o direito de obter, sem demora
                      injustificada, do responsável pelo tratamento a
                      retificação dos dados pessoais e, bem assim, o direito a
                      que os seus dados pessoais incompletos sejam completados,
                      incluindo por meio de uma declaração adicional. Em caso de
                      retificação dos dados, a <b>RH OPCO - ACADEMIA, L.DA</b>,
                      comunica a cada destinatário a quem os dados tenham sido
                      transmitidos a respetiva retificação, salvo se tal
                      comunicação se revelar impossível ou implicar um esforço
                      desproporcionado para a <b>RH OPCO - ACADEMIA, L.DA</b> */}
                      ,.
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem19')}
                    {/* Direito ao apagamento dos dados (artigo 17.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem20')}
                      {/* O titular tem o direito de obter do responsável pelo
                      tratamento o apagamento dos seus dados pessoais quando se
                      aplique um dos seguintes motivos: */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem21')}
                        {/* Os Dados do Titular deixarem de ser necessários para a
                        finalidade que motivou a sua recolha ou tratamento; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem22')}
                        {/* O Titular retirar o consentimento em que se baseia o
                        tratamento dos dados e não existir outro fundamento
                        jurídico para o referido tratamento; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem23')}
                        {/* O Titular tenha exercido o direito de oposição previsto
                        na cláusula 13 e não existam interesses legítimos
                        prevalecentes que justifiquem o tratamento; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem24')}
                        {/* Caso os Dados do Titular sejam tratados ilicitamente; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem25')}
                        {/* Caso os Dados do Titular tiverem de ser apagados para o
                        cumprimento de uma obrigação jurídica a que a
                        <b>RH OPCO - ACADEMIA, L.DA</b>, esteja sujeita. */}
                      </li>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem26')}

                        {/* Nos termos legais aplicáveis, a
                        <b>RH OPCO - ACADEMIA, L.DA</b>, não tem a obrigação de
                        apagar os Dados do Titular na medida em que o tratamento
                        se revele necessário à execução de um contrato no qual o
                        Titular dos dados é parte ou ao cumprimento de uma
                        obrigação legal a que a <b>RH OPCO - ACADEMIA, L.DA</b>,
                        esteja sujeita ou para efeitos de declaração, exercício
                        ou defesa de um direito da
                        <b>RH OPCO - ACADEMIA, L.DA</b>, num processo judicial. */}
                      </p>
                      {/* <p>
                        Em caso de apagamento dos dados, a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>, comunica a cada
                        destinatário/entidade a quem os dados tenham sido
                        transmitidos o respetivo apagamento, salvo se tal
                        comunicação se revelar impossível ou implicar um esforço
                        desproporcionado para a <b>RH OPCO - ACADEMIA, L.DA</b>
                        ,.{' '}
                      </p>
                      <p>
                        Quando a <b>RH OPCO - ACADEMIA, L.DA</b>, tiver tornado
                        públicos os Dados do Titular e for obrigada a apagá-los
                        ao abrigo do direito ao apagamento, a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>, compromete-se a
                        assegurar as medidas que forem razoáveis, incluindo de
                        carácter técnico, tendo em consideração a tecnologia
                        disponível e os custos da sua aplicação, para informar
                        os responsáveis pelo tratamento efetivo dos dados
                        pessoais de que o Titular lhes solicitou o apagamento
                        das ligações para esses dados pessoais, bem como das
                        cópias ou reproduções dos mesmos.{' '}
                      </p> */}
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem27')}
                    {/* Direito à limitação do tratamento (artigo 18.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem28')}
                      {/* O titular tem o direito à limitação das operações de
                      tratamento dos seus dados se se aplicar uma das seguintes
                      situações: */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem29')}
                        {/* Se contestar a exatidão dos dados pessoais, durante um
                        período que permita à <b>RH OPCO - ACADEMIA, L.DA</b>,
                        verificar a sua exatidão; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem30')}
                        {/* Se o tratamento for ilícito e o Titular se opuser ao
                        apagamento dos dados, solicitando, em contrapartida, a
                        limitação da sua utilização; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem31')}
                        {/* Se a <b>RH OPCO - ACADEMIA, L.DA</b>, já não precisar
                        dos Dados do Titular para fins de tratamento, mas esses
                        dados sejam requeridos pelo Titular para efeitos de
                        declaração, exercício ou defesa de um direito num
                        processo judicial; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem32')}
                        {/* Caso o Titular se tenha oposto ao tratamento, até se
                        verificar que os motivos legítimos da{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>, L.DA, prevalecem sobre
                        os do Titular. */}
                      </li>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem33')}
                        {/* Quando os Dados do Titular sejam objeto de limitação, só
                        poderão, à exceção da conservação, ser tratados com o
                        consentimento do Titular ou para efeitos de declaração,
                        exercício ou defesa de um direito num processo judicial,
                        de defesa dos direitos de outra pessoa singular ou
                        coletiva, ou por motivos de interesse público legalmente
                        previstos.{' '} */}
                      </p>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem34')}
                        {/* O Titular que tiver obtido a limitação do tratamento dos
                        seus dados nos casos acima referidos será informado pela{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>, antes de ser anulada a
                        limitação ao tratamento.{' '} */}
                      </p>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem35')}
                        {/* Em caso de limitação do tratamento dos dados, a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>, comunicará a cada
                        destinatário a quem os dados tenham sido transmitidos a
                        respetiva limitação, salvo se tal comunicação se revelar
                        impossível ou implicar um esforço desproporcionado para
                        a <b>RH OPCO - ACADEMIA, L.DA</b>. */}
                      </p>
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem36')}
                    {/* Direito à notificação (artigo 19.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem37')}
                      {/* O responsável pelo tratamento comunica a cada destinatário
                      a quem os dados pessoais tenham sido transmitidos qualquer
                      retificação ou apagamento dos mesmos ou limitação do
                      tratamento, exceto se essa comunicação for impossível ou
                      implicar um esforço desproporcionado. */}
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem38')}
                    {/* Direito de portabilidade dos dados pessoais (artigo 20.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem39')}
                      {/* O titular dos dados tem o direito de receber os dados
                      pessoais que lhe digam respeito e que tenha fornecido a um
                      responsável pelo tratamento, num formato estruturado, de
                      uso corrente e de leitura automática e o direito de
                      transmitir esses dados a outro responsável pelo tratamento
                      sem que o primeiro o possa impedir se: */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem40')}
                        {/* O tratamento se basear no consentimento ou num contrato
                        de que o Titular é parte; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem41')}
                        {/* O tratamento for realizado por meios automatizados. */}
                      </li>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem42')}
                        {/* O direito de portabilidade não inclui dados inferidos
                        nem dados derivados, isto é, dados pessoais que sejam
                        gerados pela
                        <b>RH OPCO - ACADEMIA, L.DA</b> como consequência ou
                        resultado da análise dos dados objeto de tratamento, mas
                        apenas os dados pessoais que o Titular legalmente cedeu.{' '} */}
                      </p>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem43')}
                        {/* O Titular tem o direito a que os dados pessoais sejam
                        transmitidos diretamente entre os responsáveis pelo
                        tratamento, sempre que tal seja tecnicamente possível. */}
                      </p>
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem44')}
                    Direito de oposição (artigo 21.º)
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem45')}
                      {/* O titular dos dados tem o direito de se opor, a qualquer
                      momento, por motivos relacionados com a sua situação
                      particular, ao tratamento dos seus dados nos seguintes
                      casos:{' '} */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem46')}
                        {/* Quando a recolha e tratamento dos dados pessoais se
                        baseie na prossecução de interesses legítimos pela{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>
                        ou por terceiros no âmbito da relação relevante
                        estabelecida com o Titular, designadamente para efeitos
                        de comercialização direta o que abrange a definição de
                        perfis na medida em que esteja relacionada com essa
                        comercialização direta; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem47')}
                        {/* Quando o tratamento for efetuado para fins que não sejam
                        aqueles para os quais os dados pessoais foram
                        recolhidos, incluindo a definição de perfis; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem48')}
                        {/* Quando os dados pessoais forem tratados para fins
                        estatísticos. */}
                      </li>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem49')}
                        {/* A <b>RH OPCO - ACADEMIA, L.DA</b> cessará o tratamento
                        dos Dados do Titular, salvo se apresentar razões
                        imperiosas e legítimas para esse tratamento que
                        prevaleçam sobre os interesses, direitos e liberdades do
                        Titular, ou para efeitos de declaração, exercício ou
                        defesa de um direito da <b>RH OPCO - ACADEMIA, L.DA</b>{' '}
                        num processo judicial.{' '} */}
                      </p>
                      <p>
                        {t('common:privacy-page.item12-text.item12-subitem50')}
                        {/* Caso o Titular se oponha ao tratamento dos seus dados
                        para efeitos de comercialização direta, a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>
                        cessa o tratamento dos dados para esse fim.{' '} */}
                      </p>
                    </ul>
                  </li>
                  <li>
                    {t('common:privacy-page.item12-text.item12-subitem51')}
                    {/* Direito à não sujeição a decisões automatizadas (artigo
                    22.º) */}
                    <ul>
                      {t('common:privacy-page.item12-text.item12-subitem52')}O
                      {/* titular dos dados tem o direito de não ficar sujeito a
                      nenhuma decisão tomada exclusivamente com base no
                      tratamento automatizado, incluindo a definição de perfis,
                      que produza efeitos na sua esfera jurídica ou que o afete
                      significativamente de forma similar, salvo se a decisão:{' '} */}
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem53')}
                        {/* For necessária para a celebração ou a execução de um
                        contrato entre o Titular e a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem54')}
                        {/* For autorizada por legislação a que a{' '}
                        <b>RH OPCO - ACADEMIA, L.DA</b>
                        estiver sujeita, ou; */}
                      </li>
                      <li>
                        {t('common:privacy-page.item12-text.item12-subitem55')}
                        {/* For baseada no consentimento explícito do Titular. */}
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </p>
            <p>
              <p>
                {' '}
                <b>13. {t('common:privacy-page.item12-text.item13-title')}</b>
              </p>
              <p>{t('common:privacy-page.item12-text.item13-text')}</p>
              {/* <p>
                <b>RH OPCO - ACADEMIA, L.DA</b> dará resposta por escrito
                (incluindo por meios eletrónicos) ao pedido do Titular no prazo
                máximo de 30 dias a contar da receção do pedido, salvo em casos
                de especial complexidade, em que esse prazo pode ser prorrogado
                até dois meses.
              </p> */}
              {/* <>
                Se os pedidos apresentados pelo Titular forem manifestamente
                infundados ou excessivos, nomeadamente devido ao seu carácter
                repetitivo, a <b>RH OPCO - ACADEMIA, L.DA</b> reserva se o
                direito de cobrar custos administrativos ou recusar-se a dar
                seguimento ao pedido.
              </> */}
            </p>
            <p>
              <p>
                {' '}
                <b> 14. {t('common:privacy-page.item12-text.item14-title')}</b>
              </p>
              <p>
                {t(
                  'common:privacy-page.item12-text.item14-text.item14-subitem1',
                )}
                {/* Em caso de violação de dados e na medida em que tal violação
                seja suscetível de implicar um elevado risco para os direitos e
                liberdades do Titular, a <b>RH OPCO - ACADEMIA, L.DA</b>
                compromete se a comunicar a violação de dados pessoais ao
                Titular em causa no prazo de 72 horas a contar do conhecimento
                do incidente. */}
              </p>
              <p>
                {t(
                  'common:privacy-page.item12-text.item14-text.item14-subitem2',
                )}
                {/* Nos termos legais, a comunicação ao Titular não é exigida nos
                seguintes casos: */}
              </p>
              <ul>
                <li>
                  {t(
                    'common:privacy-page.item12-text.item14-text.item14-subitem3',
                  )}
                  {/* Caso a <b>RH OPCO - ACADEMIA, L.DA</b> tenha aplicado medidas
                  de proteção adequadas, tanto técnicas como organizativas, e
                  essas medidas tenham sido aplicadas aos dados pessoais
                  afetados pela violação, especialmente medidas que tornem os
                  dados pessoais incompreensíveis para qualquer pessoa não
                  autorizada a aceder a esses dados, tais como a cifragem; */}
                </li>
                <li>
                  {t(
                    'common:privacy-page.item12-text.item14-text.item14-subitem4',
                  )}
                  {/* Caso a <b>RH OPCO - ACADEMIA, L.DA</b> tenha tomado medidas
                  subsequentes que assegurem que o elevado risco para os
                  direitos e liberdades do Titular já não é suscetível de se
                  concretizar; ou */}
                </li>
                <l>
                  {/* Caso a comunicação ao Titular implique um esforço
                  desproporcionado para a <b>RH OPCO - ACADEMIA, L.DA</b>. Nesse
                  caso, a <b>RH OPCO - ACADEMIA, L.DA</b>
                  fará uma comunicação pública ou tomará uma medida semelhante
                  através da qual o Titular será informado. */}
                </l>
              </ul>
            </p>
            <p>
              <p>
                <b> 15. {t('common:privacy-page.item12-text.item15-title')}</b>
              </p>
              <p>{t('common:privacy-page.item12-text.item15-text')}</p>
            </p>
            <p>
              <p>
                <b> 16. {t('common:privacy-page.item12-text.item16-title')}</b>
              </p>
              <p>
                {t('common:privacy-page.item12-text.item16-text')}
                {/* Se aceder à nossa Plataforma, informamos que trataremos os seus
                dados de navegação para efeitos analíticos e estatísticos, isto
                é, para entender de que forma os utilizadores interagem com a
                nossa Plataforma e com as ações que implementamos em outras
                páginas web e apps, para que possamos melhorar os nossos
                serviços. */}
              </p>
              <p>
                {/* Além disso, realizaremos ocasionalmente inquéritos e ações de
                qualidade destinados a conhecer o grau de satisfação dos nossos
                clientes e utilizadores e a detetar as áreas em que podemos
                melhorar. */}
              </p>
            </p>
            <p>
              <p>
                <b> 17. {t('common:privacy-page.item12-text.item17-title')}</b>
              </p>
              <p>
                {t('common:privacy-page.item12-text.item18-title')}
                {/* Considerando que esta Política de Privacidade pode ser objeto de
                atualizações, aconselhamos a sua consulta regular. */}
              </p>
            </p>
            <p>
              <p>
                <b> 18. {t('common:privacy-page.item12-text.item18-title')}</b>
              </p>
              <p>
                {t('common:privacy-page.item12-text.item18-text')}
                {/* A Política de Privacidade, bem como a recolha, tratamento ou
                transmissão de Dados do Titular, são regidos pelo disposto no
                Regulamento (UE) 2016/679, do Parlamento Europeu e do Conselho,
                de 27 de abril de 2016 e pela legislação e regulamentação
                aplicáveis em Portugal. */}
              </p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyComponent;
