import React from 'react';

import CareersForm2 from '../components/Careers/CareersForm2';
import HomeCareers from '../components/Careers/HomeCareers';

const Careers = () => {
  return (
    <>
      <HomeCareers />
      <CareersForm2 />
    </>
  );
};

export default Careers;
