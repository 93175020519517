/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import PrivacyComponent from '../Footer/PrivacyComponent';

export const CookieInfoText = () => {
  const [show, setShow] = useState(false);
  const [title, settitle] = useState('');
  const [text, settext] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    const title = 'Política Privacidade';
    const text = <PrivacyComponent />;
    settitle(title);
    settext(text);
    setShow(true);
  };

  return (
    <>
      Este website utiliza cookies para garantira melhor experiência ao
      utilizador.{' '}
      <a href="#" class="" onClick={handleShow}>
        Saiba mais
      </a>
      <div className="text-center">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="text-center">
            <Modal.Title>
              <h3>
                <strong>{title}</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">{text}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="text-center"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
