import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SingleCourse from '../components/Courses/singleCourse';
import Spinner from '../components/Spinner/Spinner';
import { getFormations } from '../redux/formationsCatalogSlicev2';
import './event.css';

const Course2 = () => {
  const dispatch = useDispatch();
  const catalogData = useSelector((state) => state.formationCatalog2.data);
  let { courseID } = useParams();
  const [course, setCourse] = useState();

  useEffect(() => {
    function getCourse() {
      let course_temp = catalogData.find(
        (course) =>
          course.fields.title
            .split(' ')
            .join('-')
            .replace('---', '-')
            .replace('/', '-') === courseID,
      );
      if (course_temp !== undefined) {
        setCourse(course_temp);
      }
    }
    dispatch(getFormations());
    getCourse();
  }, [catalogData, courseID, dispatch]);

  if (!catalogData.length > 0) {
    return (
      <>
        {/* <section>
          <div className="container">
            <div class="full-page-loader">
              <img width="200" src="/images/logowebp.webp" alt="OPCO logo" />
            </div>
          </div>
        </section> */}
        <Spinner />
      </>
    );
  }
  return <>{course && <SingleCourse course={course} />}</>;
};

export default Course2;
