import { useState, useEffect } from 'react';

import axios from 'axios';

import Client from '../../Contentfull';
import data_de from '../../components/Courses/data_de';
import data_en from '../../components/Courses/data_en';
import data_es from '../../components/Courses/data_es';
import data_fr from '../../components/Courses/data_fr';
import data_pt from '../../components/Courses/data_pt';

const base_URL = process.env.REACT_APP_API_URL;

export default function useData() {
  const [events, setEvents] = useState([]);
  const [eventsSlider, setEventsSlider] = useState([]);
  const [courses, setCourses] = useState(data_pt);
  const [filteredCourses, setFilteredCourses] = useState(data_pt);
  const [presential, setPresential] = useState(0);
  const [online, setOnline] = useState(0);
  const [partner, setPartner] = useState('');
  const [category1, setcategory1] = useState('');
  const [category2, setcategory2] = useState('');
  const [setcategory3] = useState('');
  const [select1Value, setSelect1Value] = useState('default');
  const [select2Value, setSelect2Value] = useState('default');
  const [select3Value, setSelect3Value] = useState('default');
  const [select4Value, setSelect4Value] = useState('default');
  const [searchTextBox, setsearchTextBox] = useState('');
  const [banners] = useState([]);
  const [hero] = useState([]);
  const [news] = useState([]);
  const [catalogs] = useState([]);
  const [emailData, setEmail] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    information: '',
  });
  const [emailStatus, setemailStatus] = useState('');
  const [emailError, setemailError] = useState('');
  const [hasResponse, sethasResponse] = useState(false);
  const [formStatus, setformStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [subtotal, setsubtotal] = useState(0);
  const [orderStatus, setOrderStatus] = useState('');
  let localCart = localStorage.getItem('cart');
  useEffect(() => {
    getEvents();
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localCart = JSON.parse(localCart);
    if (localCart) setItems(localCart);
  }, [category1, category2]);

  function setSubject(subject) {
    let name = 'subject';
    setEmail((prevState) => ({ ...prevState, [name]: subject }));
  }

  async function sendForm(form_data) {
    const date1 = new Intl.DateTimeFormat('pt-PT').format(form_data.date);
    const date2 = new Intl.DateTimeFormat('pt-PT').format(form_data.dateBirth);
    let formData = new FormData();
    formData.append('inHouse', form_data.inHouse);
    formData.append('inHome', form_data.inHome);
    formData.append('date', date1);
    formData.append('firstName', form_data.firstName);
    formData.append('lastName', form_data.lastName);
    formData.append('dateBirth', date2);
    formData.append('company', form_data.company);
    formData.append('department', form_data.department);
    formData.append('street', form_data.street);
    formData.append('zipCodeCity', form_data.zipCodeCity);
    formData.append('country', form_data.country);
    formData.append('telephone', form_data.telephone);
    formData.append('email', form_data.email);
    formData.append('fileProofVDA63', form_data.fileProofVDA63);
    formData.append('fileAuditQualif', form_data.fileAuditQualif);
    formData.append('fileFurtherDocs', form_data.fileFurtherDocs);
    formData.append('fileProofAutoCore', form_data.fileProofAutoCore);
    formData.append('fileWorkExperience', form_data.fileWorkExperience);
    formData.append('fileOtherDocs', form_data.fileOtherDocs);
    formData.append('note', form_data.note);
    formData.append('informationCorrect', form_data.informationCorrect);
    axios
      .post(`${base_URL}/email/vda63exam`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        setformStatus(response.status);
      })
      .catch(function (error) {
        setformStatus(error.response.status);
      });
  }
  async function getProducts() {
    axios
      .get('https://www.ifportugal.pt/wp-json/wc/v3/products', {
        auth: {
          username: process.env.REACT_APP_WOO_CONSUMER_USERNAME,
          password: process.env.REACT_APP_WOO_CONSUMER_PASSWORD,
        },
        per_page: 100,
      })
      .then(function (response) {
        setProducts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // ADD TO CART
  function addToCart(product) {
    // Check if product is already in the cart
    let exists = items.filter((item) => item.id === product.id);

    // If exists update quantity
    if (exists.length > 0) {
      const newItems = [...items];
      newItems.forEach((item) => {
        if (item.id === exists[0].id) {
          item.quantity = item.quantity + 1;
        }
      });

      //subtotal = this.calculateSubtotal();
      setItems(newItems);
      setsubtotal(calculateSubtotal());
      return;
    }

    // If product is NOT in the cart, add it.
    const formattedProduct = {
      id: product.id,
      name: product.name,
      price: parseFloat(product.price),
      image: product.images[0].src,
      quantity: 1,
    };

    //addProduct(formattedProduct);
    const cartCopy = [...items, formattedProduct];
    setItems(cartCopy);
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem('cart', stringCart);
  }

  // REMOVE FROM CART
  function removeFromCart(id) {
    const updatedItems = items.filter((item) => item.id !== id);
    let subtotal = 0;
    if (updatedItems.length > 0) {
      updatedItems.forEach((item) => {
        subtotal = subtotal + item.price * item.quantity;
        subtotal = round(subtotal, 2);
      });
    }
    setItems(updatedItems);
    setsubtotal(subtotal);
    let stringCart = JSON.stringify(updatedItems);
    localStorage.setItem('cart', stringCart);
  }

  // CALCULATE SUBTOTAL
  function calculateSubtotal() {
    let subtotalTemp = 0;
    items.forEach((item) => {
      subtotalTemp = subtotalTemp + item.price * item.quantity;
    });

    subtotalTemp = round(subtotalTemp, 2);

    setsubtotal(subtotalTemp);
  }

  // ROUND HELPER
  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  function makeOrder(orderData) {
    axios({
      method: 'post',
      url: 'https://www.ifportugal.pt/wp-json/wc/v3/orders',
      data: {
        payment_method: orderData.payment_method,
        customer_note: orderData.vat,
        payment_method_title: orderData.payment_method_title,
        set_paid: false,
        status: 'on-hold',
        meta_data: [
          {
            id: 123,
            key: 'NIF',
            value: orderData.nif,
          },
        ],
        billing: {
          first_name: orderData.first_name,
          last_name: orderData.last_name,
          address_1: orderData.address_1,
          city: orderData.city,
          postcode: orderData.postcode,
          country: orderData.country,
          email: orderData.email,
          phone: orderData.phone,
          company: orderData.company,
        },
        shipping: {
          first_name: orderData.first_name,
          last_name: orderData.last_name,
          address_1: orderData.address_1,
          city: orderData.city,
          postcode: orderData.postcode,
          country: orderData.country,
          company: orderData.company,
        },
        line_items: formatLineItems(),
        shipping_lines: orderData.shipping_lines,
      },
      auth: {
        username: process.env.REACT_APP_WOO_CONSUMER_USERNAME,
        password: process.env.REACT_APP_WOO_CONSUMER_PASSWORD,
      },
    })
      .then((response) => {
        //update order status
        setOrderStatus(response.status);
        //update items
        setItems([]);
        localStorage.removeItem('cart');
      })
      .catch((error) => {
        setOrderStatus(error.status);
      });
  }
  function formatLineItems() {
    let lineItems = [];

    items.forEach((item) => {
      lineItems.push({
        product_id: item.id,
        quantity: item.quantity,
      });
    });

    return lineItems;
  }
  async function getEvents() {
    try {
      setEvents([]);
      let response = await Client.getEntries({
        content_type: 'event',
      });
      let items = response.items;
      let today = new Date();
      let tempSliderArray = [];
      items.map((item) => {
        let tempObj = {
          title: '',
          start: '',
          end: '',
          allDay: '',
          url: '',
          info: { duration: '', schedule: '', image: '', urlParameter: '' },
          urlParameter: '',
        };
        tempObj.title = item.fields.title;
        tempObj.start = new Date(item.fields.start);
        tempObj.end = new Date(item.fields.end);
        tempObj.allDay = item.fields.allDay;
        tempObj.url = item.fields.url;
        tempObj.info.image = item.fields.image.fields.file.url;
        tempObj.info.duration = item.fields.duration;
        tempObj.info.schedule = item.fields.schedule;
        tempObj.info.urlParameter = item.fields.urlParameter;
        setEvents((arr) => [...arr, tempObj]);
        if (tempObj.start > today) {
          tempSliderArray.push(tempObj);
        }
        return null;
      });
      setEventsSlider(tempSliderArray);
    } catch (error) {
      console.log(error);
    }
  }

  function getCatalogUrl(filename) {
    let index = catalogs.findIndex((item) => item.filename === filename);
    return catalogs[index].url;
  }

  function setCoursesFile() {
    const languageLocalStorage = localStorage.getItem('language-opco');
    if (languageLocalStorage) {
      if (languageLocalStorage === 'pt') {
        setCourses(data_pt);
      }
      if (languageLocalStorage === 'en') {
        setCourses(data_en);
      }
      if (languageLocalStorage === 'fr') {
        setCourses(data_fr);
      }
      if (languageLocalStorage === 'es') {
        setCourses(data_es);
      }
      if (languageLocalStorage === 'de') {
        setCourses(data_de);
      }
    } else {
      setCourses(data_pt);
    }
  }

  function getCourses() {
    return filterCourses;
  }
  function getOnlinePresential() {
    var present_temp = 0;
    var online_temp = 0;
    filteredCourses.forEach((course) => {
      if (course.presential.props.id === 'true') {
        present_temp++;
      }
      if (course.online.props.id === 'true') {
        online_temp++;
      }
    });
    setPresential(present_temp);
    setOnline(online_temp);
  }
  function applyPartnerFilter(value) {
    let tempCourses;
    if (value !== 'default') {
      tempCourses = [...courses];
      tempCourses = tempCourses.filter(
        (course) =>
          course.category1 === category1 &&
          course.category2 === category2 &&
          course.partner.toLowerCase() === value.toLowerCase(),
      );
    }
    if (value === 'default') {
      tempCourses = [...courses];
      tempCourses = tempCourses.filter(
        (course) =>
          course.category1 === category1 && course.category2 === category2,
      );
    }
    setFilteredCourses(tempCourses);
  }

  function handleImageChange(event) {
    const target = event.target;
    const value = target.id;
    setcategory1(value);
    setSelect4Value('');
    setPartner('');
    setcategory3('');
    setcategory2('');
    //setcategory2("default");
    if (value === 'Formação') {
      const temp = 'Automóvel';
      setcategory2(temp);
      let tempCourses = courses.filter(
        (course) =>
          course.category1 === value &&
          course.category2 === temp &&
          course.partner === 'VDA QMC',
      );
      setFilteredCourses(tempCourses);
    } else {
      let tempCourses = courses.filter((course) => course.category1 === value);
      setFilteredCourses(tempCourses);
    }
  }
  function handleSelectPartnerChange(event) {
    const target = event.target;
    const value = target.value;
    setSelect4Value(value);
    setPartner(value);
    setcategory3(value);
    applyPartnerFilter(value);
  }

  function handleSelectChange(event) {
    const target = event.target;
    const id = target.id;
    const value = target.value;
    setcategory2(value);
    if (id === 'select1') {
      setSelect1Value(value);
      setcategory2(value);
    }
    if (id === 'select2') {
      setSelect2Value(value);
      setcategory2(value);
    }
    if (id === 'select3') {
      setSelect3Value(value);
      setcategory2(value);
    }
    applyCategorie2Filter(value);
  }
  function applyCategorie2Filter(value) {
    let tempCourses = [...courses];
    if (value !== 'default') {
      tempCourses = tempCourses.filter(
        (course) =>
          course.category2 === value && course.category1 === category1,
      );
    }
    setFilteredCourses(tempCourses);
  }

  function filterCourses() {
    //all the courses
    let tempCourses = [...courses];
    //filter by category1
    if (category1 !== '') {
      tempCourses = tempCourses.filter(
        (course) => course.category1 === category1,
      );
    }
    //filter by category2
    if (category2 !== '') {
      tempCourses = tempCourses.filter(
        (course) => course.category2 === category2,
      );
    }
    setFilteredCourses(tempCourses);
    getOnlinePresential();
  }

  return {
    getEvents,
    getCourses,
    setCoursesFile,
    applyCategorie2Filter,
    setSelect1Value,
    setSelect2Value,
    setSelect3Value,
    handleSelectChange,
    handleImageChange,
    setsearchTextBox,
    setSubject,
    setemailStatus,
    setemailError,
    sethasResponse,
    handleSelectPartnerChange,
    getCatalogUrl,
    sendForm,
    setformStatus,
    addToCart,
    removeFromCart,
    calculateSubtotal,
    round,
    makeOrder,
    setOrderStatus,
    partner,
    select4Value,
    hasResponse,
    emailError,
    emailStatus,
    emailData,
    news,
    searchTextBox,
    select1Value,
    select2Value,
    select3Value,
    events,
    eventsSlider,
    courses,
    presential,
    online,
    filteredCourses,
    category1,
    category2,
    banners,
    catalogs,
    formStatus,
    products,
    items,
    subtotal,
    orderStatus,
    hero,
  };
}
