import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const base_URL = process.env.REACT_APP_API_URL;

export const sendFormCareers = createAsyncThunk(
  "careersSlice/sendFormCareers",
  async ({ formData }) => {
    const response = await axios.post(`${base_URL}/cv/send`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.status;
  }
);

const initialState = {
  status: null,
  sendFormStatus: null,
};

const careersSlice = createSlice({
  name: "careersSlice",
  initialState,
  extraReducers: {
    [sendFormCareers.pending]: (state) => {
      state.status = "Pending";
    },
    [sendFormCareers.fulfilled]: (state, action) => {
      state.status = "Fulfilled";
      state.sendFormStatus = action.payload;
    },
    [sendFormCareers.rejected]: (state) => {
      state.status = "Rejected";
    },
  },
});

export default careersSlice.reducer;
